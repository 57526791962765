import React, { useEffect, useState } from "react";
import "./allagents.scss";
import DataTable from "./DataTable/DataTable";
import ViewDetail from "./ViewDetail";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import { useHistory } from "react-router-dom";
import Web3 from "web3";
import { toast } from "react-toastify";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import moment from "moment";
const AllAgents = ({
  user,
  setindexwait,
  GetMyData,
  routes,
  setRoutes,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
}) => {
  const [loader, setLoader] = useState(false);
  const [leadersLoader, setLeadersLoader] = useState(false);
  const [blockUnblock, setBlockUnblock] = useState(false);
  const [codeBlockFilter, setCodeBlockFilter] = useState(true);
  const [deletedFilter, setDeletedFilter] = useState(false);
  const [selectedLeaders, setSelectedLeaders] = useState([]);
  const [searchBy, setSearchBy] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const userObj = JSON.parse(localStorage.getItem("user"));
  const val = localStorage.getItem("accessToken");
  const project = localStorage.getItem("project");

  const [show, setShow] = useState(false);
  const [agents, setAgents] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState(null);
  const [addr, setAddr] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [limit, setLimit] = useState(1);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(0);
  const [search0, setSearch0] = useState("");
  const [userDel, setUserDel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderNames2, setLeaderNames2] = useState([]);
  const [leaderFilter, setLeaderFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState();
  const [inActiveFilter, setInActiveFilter] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roleTypeFilter, setRoleTypeFilter] = useState("");
  const [premiumFilter, setPremiumFilter] = useState();

  const [searchQuery, setSearchQuery] = useState("");
  const [myTree, setMyTree] = useState(false);

  const [orderField, setOrderField] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState("-1");

  const [roundsData, setRoundsData] = useState([]);

  const [privateSaleFilter, setPrivateSaleFilter] = useState([]);

  const [searchInputLeaders, setSearchInputLeaders] = useState("");

  const flattenNestedArrays = (nestedArray) => {
    let result = [];

    const flatten = (array) => {
      array.forEach((item) => {
        result.push(item);

        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key]) && item[key].length > 0) {
            flatten(item[key]);
          }
        });
      });
    };

    flatten(nestedArray);
    return result;
  };
  const handleSearchChangeLeaders = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInputLeaders(searchValue);

    if (searchValue === "") {
      // Restore the original data when search is cleared
      setLeaderNames2(leaderNames);
    } else {
      const flattened = flattenNestedArrays(leaderNames); // Flatten the original data for searching
      const filtered = flattened.filter((item) =>
        item.name.toLowerCase().includes(searchValue)
      );
      setLeaderNames2(filtered);
    }
  };

  const handleCheckboxChangeRounds = (projectName, round = null) => {
    setPrivateSaleFilter((prevFilters) => {
      const existingProject = prevFilters.find(
        (item) => item.privateSale === projectName
      );

      if (existingProject) {
        // If the project exists in the filter
        if (round) {
          // If a specific round is being checked/unchecked
          const updatedRounds = existingProject.round.includes(round)
            ? existingProject.round.filter((r) => r !== round)
            : [...existingProject.round, round];

          // If no rounds are left after unchecking, remove the project entirely
          if (updatedRounds.length === 0) {
            return prevFilters.filter(
              (item) => item.privateSale !== projectName
            );
          }

          // Update the rounds for this project
          return prevFilters.map((item) =>
            item.privateSale === projectName
              ? { ...item, round: updatedRounds }
              : item
          );
        } else {
          // If no specific round is passed, remove the entire project from the filter
          return prevFilters.filter((item) => item.privateSale !== projectName);
        }
      } else {
        // If the project doesn't exist in the filter
        return [
          ...prevFilters,
          {
            privateSale: projectName,
            round: round ? [round] : [],
          },
        ];
      }
    });
  };
  const roles = [
    "god_agent",
    "mega_agent",
    "super_agent",
    "agent",
    "mini_agent",
  ];
  const history = useHistory();

  const handleClose = () => setShow(false);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setName("");
    setAddr("");
    setEmail("");
    setRole(null);
    setShow1(false);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  function isValidEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  function isAddressValid(address) {
    return Web3.utils.isAddress(address);
  }

  const handleCheckboxChange = (role) => {
    setSelectedRoles((prevRoles) => {
      if (role === "all") {
        // If "All Roles" is checked, select/deselect all roles
        if (prevRoles.length === roles.length) {
          return []; // Uncheck all roles
        } else {
          return roles; // Check all roles
        }
      } else {
        // Handle individual role check/uncheck
        if (prevRoles.includes(role)) {
          return prevRoles.filter((r) => r !== role); // Remove role
        } else {
          return [...prevRoles, role]; // Add role
        }
      }
    });
  };
  const isAllRolesSelected = selectedRoles.length === roles.length;

  useEffect(() => {
    getLeaderNames();
    getRoundsAndProjects();
    // getTomiPrice();
  }, []);

  useEffect(() => {
    if (user) {
      GetAgents();
    }
  }, [
    privateSaleFilter?.length,
    selectedLeaders?.length,
    selectedRoles?.length,
    user,
    myTree,
    activeFilter,
    inActiveFilter,
    premiumFilter,
    leaderFilter,
    roleTypeFilter,
    searchQuery,
    orderField,
    orderDirection,
    codeBlockFilter,
    deletedFilter,
    page,
    startDate,
    endDate,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const getLeaderNames = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/leaders-tree`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        setLeaderNames(response?.data?.data?.data);
        setLeaderNames2(response?.data?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const getRoundsAndProjects = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/sale-rounds/admin/filter`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        setRoundsData(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const GetAgents = () => {
    setAgents([]);
    setLeadersLoader(true);

    var config = {
      method: "post",
      url: `${Api_Url}/users/admin/leaders`,
      data: {
        limit: 10,
        offset: page + 1,
        ...(activeFilter !== null && { isActive: activeFilter }),

        ...(selectedLeaders?.length > 0 && {
          nameFilter: selectedLeaders?.map((leader) => leader?.name),
        }),
        ...(selectedRoles?.length > 0 && { roleFilter: selectedRoles }),
        ...(premiumFilter !== null && { isPremium: premiumFilter }),
        ...(searchQuery &&
          searchBy == "walletAddress" && {
            walletAddressFilter: searchQuery,
          }),
        ...(searchQuery &&
          searchBy == "accessCode" && { accessCodeFilter: searchQuery }),

        // walletAddressFilter:,
        // accessCodeFilter:,
        ...(privateSaleFilter?.length > 0 && {
          privateSaleFilter: privateSaleFilter,
        }),

        ...(startDate && {
          startDate: startDate,
        }),

        ...(endDate && {
          endDate: endDate,
        }),

        orderField: orderField,
        orderDirection: orderDirection,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    // let dynamicQuery = config.url;

    // if (leaderFilter) {
    //   dynamicQuery += `&name=${encodeURIComponent(leaderFilter)}`;
    // }

    // if (roleTypeFilter) {
    //   dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    // }

    // if (activeFilter) {
    //   dynamicQuery += `&active=${activeFilter}`;
    // }
    // if (inActiveFilter) {
    //   dynamicQuery += `&inActive=${inActiveFilter}`;
    // }
    // if (premiumFilter == "premium") {
    //   dynamicQuery += `&isPremium=true`;
    // } else if (premiumFilter == "basic") {
    //   dynamicQuery += `&isPremium=false`;
    // }

    // if (searchQuery) {
    //   dynamicQuery += `&search=${encodeURIComponent(searchQuery)}`;
    // }
    // if (orderField) {
    //   dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    // }
    // if (user?.role == "god_agent") {
    //   dynamicQuery += `&myTree=${myTree}`;
    // }
    // config.url = dynamicQuery;

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data?.data;
        setCount(resData.count);
        setAgents(resData?.leaders);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setPageCount(resData.pages);
        setPages(arr);

        setLeadersLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
        setLeadersLoader(false);
      });
  };

  const AddAgent = () => {
    const val = localStorage.getItem("accessToken");
    let url = null;
    if (user?.role === "admin") {
      url = `${Api_Url}/users/god-agents`;
    } else if (role === "mega_agent") {
      url = `${Api_Url}/users/mega-agents`;
    } else if (role === "super_agent") {
      url = `${Api_Url}/users/super-agents`;
    } else if (role === "agent") {
      url = `${Api_Url}/users/agents`;
    } else if (role === "mini_agent") {
      url = `${Api_Url}/users/mini-agents`;
    }
    if (name?.length > 2) {
      if (isAddressValid(addr)) {
        if (isValidEmail(email)) {
          setLoader(true);
          var config = {
            method: "post",
            url: url,
            data: {
              name: name,
              walletAddress: addr,
              email: email,
              name: name,
            },
            headers: {
              Authorization: "Bearer " + val,
            },
          };

          axios(config)
            .then(function (response) {
              setLoader(false);
              setName("");
              setAddr("");
              setEmail("");
              setRole(null);
              GetMyData();
              handleClose1();
              setShow2(true);
              GetAgents();
            })
            .catch(function (error) {
              setLoader(false);
              toast.error(error?.response?.data?.message);
              if (error?.response?.status == 401) {
                // localStorage.removeItem("accessToken");
                // history.push("/");
              }
            });
        } else {
          setError("Incorrect Email!");
        }
      } else {
        setError("Incorrect wallet address!");
      }
    } else {
      setError("Name must be of length 3!");
    }
  };

  function convertToTitleCase(input) {
    // console.log("cccc");
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  const DeleteUser = () => {
    const val = localStorage.getItem("accessToken");
    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/toggle-leaders`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        GetAgents();
        setShow(false);
        setBlockUnblock(!blockUnblock);
        // setRoutes(false);
        // setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
        toast.error(error?.message);
      });
  };

  const functiontogetback = () => {
    localStorage.setItem("indexvalue", 0);
    setindexwait(0);
  };

  const SearchByName = (e) => {
    if (e) {
      e?.preventDefault();
    }
    if (search0?.length == 0) {
      setLeaderNames(leaderNames2);
    } else if (search0?.length > 1) {
      const results = leaderNames.filter((item) =>
        item.name?.toLowerCase().includes(search0?.toLowerCase())
      );
      setLeaderNames(results);
    }
  };

  const GetSearch = (searchKey) => {
    setSearch0(searchKey);
    if (searchKey === "") {
      setLeaderFilter("");
      setLeaderNames(leaderNames2);
    } else if (searchKey?.length > 1) {
      const results = leaderNames2.filter((item) =>
        item.name?.toLowerCase().includes(searchKey?.toLowerCase())
      );
      setLeaderNames(results);
      // SearchByName(searchKey);
    }
  };

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleCheckboxChangeLeaders = (leader) => {
    setSelectedLeaders(
      (prevLeaders) =>
        prevLeaders.some((l) => l.id === leader.id)
          ? prevLeaders.filter((l) => l.id !== leader.id) // Remove the leader object if already selected
          : [...prevLeaders, { id: leader.id, name: leader.name }] // Add the leader object if not selected
    );
  };

  const getAllEventKeys = (nodes, keys = []) => {
    nodes.forEach((node) => {
      keys.push(node._id.toString());
      Object.keys(node).forEach((key) => {
        if (Array.isArray(node[key]) && node[key].length > 0) {
          getAllEventKeys(node[key], keys);
        }
      });
    });
    return keys;
  };
  const areAllChildrenSelected = (node) => {
    const childNodes = getAllDescendantNodes(node);
    return childNodes.every((child) =>
      selectedLeaders.some((leader) => leader.id === child._id)
    );
  };

  const getAllDescendantNodes = (node) => {
    let descendants = [node];
    Object.keys(node).forEach((key) => {
      if (Array.isArray(node[key])) {
        node[key].forEach((child) => {
          descendants = descendants.concat(getAllDescendantNodes(child));
        });
      }
    });
    return descendants;
  };
  const handleParentCheckboxChange = (node) => {
    const allDescendants = getAllDescendantNodes(node);
    const descendantObjects = allDescendants.map((descendant) => ({
      id: descendant._id,
      name: descendant.name,
    }));

    let newSelectedLeaders;
    if (areAllChildrenSelected(node)) {
      // Deselect all descendants
      newSelectedLeaders = selectedLeaders.filter(
        (leader) => !descendantObjects.some((desc) => desc.id === leader.id)
      );
    } else {
      // Select all descendants
      newSelectedLeaders = [
        ...new Set([...selectedLeaders, ...descendantObjects]),
      ];
    }

    setSelectedLeaders(newSelectedLeaders);
  };
  const renderHierarchy = (nodes, level = 0) => {
    return nodes.map((node, index) => (
      <Accordion.Item
        key={`${node?._id}_${index}`}
        eventKey={node?._id?.toString()}
      >
        <Accordion.Header className={`accordion-header level-${level}`}>
          <div
            className="maininnerbtn"
            style={{ paddingLeft: `${level * 20}px` }}
          >
            <label className="cyberpunk-checkbox-label">
              <input
                type="checkbox"
                className="cyberpunk-checkbox"
                checked={areAllChildrenSelected(node)}
                onChange={() => handleParentCheckboxChange(node)}
              />
            </label>
            <div className="projectmain">
              <p className="projectpara">{node?.name}</p>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {Object.keys(node).map((key) => {
            if (Array.isArray(node[key]) && node[key].length > 0) {
              return (
                <Accordion key={key} defaultActiveKey="0">
                  {renderHierarchy(node[key], level + 1)}
                </Accordion>
              );
            }
            return null;
          })}
        </Accordion.Body>
      </Accordion.Item>
    ));
  };

  const allEventKeys = getAllEventKeys(leaderNames);

  const formatDates = (times) => {
    let date = null;
    let date1 = null;
    if (Array.isArray(times)) {
      let a = new Date(times[0]?.startDate);
      let b = new Date(times[0]?.endDate);
      date = moment(a).format("Y-MM-DDT00:00:00");
      date1 = moment(b).format("Y-MM-DDT23:59:59");
      setState(times);
    } else if (times == "1") {
      var date12 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 0; //1 month
      var myDate = new Date();
      myDate.setTime(myDate.getTime() - dateOffset);
      date = moment(myDate).utc().format("Y-MM-DDT00:00:00");
      date1 = moment(date12).utc().format("Y-MM-DDT23:59:59");
    } else if (times == "3") {
      var date12 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 3; //1 month
      var myDate = new Date();
      myDate.setTime(myDate.getTime() - dateOffset);
      date = moment(myDate).utc().format("Y-MM-DDT00:00:00");
      date1 = moment(date12).utc().format("Y-MM-DDT23:59:59");
    } else if (times == "seven") {
      var date12 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 7; //1 month
      var myDate = new Date();
      myDate.setTime(myDate.getTime() - dateOffset);
      date = moment(myDate).utc().format("Y-MM-DDT00:00:00");
      date1 = moment(date12).utc().format("Y-MM-DDT23:59:59");
    } else if (times == "year") {
      var date12 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 365; //1 month
      var myDate = new Date();
      myDate.setTime(myDate.getTime() - dateOffset);
      date = moment(myDate).utc().format("Y-MM-DDT00:00:00");
      date1 = moment(date12).utc().format("Y-MM-DDT23:59:59");
    } else if (times == "month") {
      var date12 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 31; //1 month
      var myDate = new Date();
      myDate.setTime(myDate.getTime() - dateOffset);
      date = moment(myDate).utc().format("Y-MM-DDT00:00:00");
      date1 = moment(date12).utc().format("Y-MM-DDT23:59:59");
    }
    setStartDate(date);
    setEndDate(date1);
  };

  return (
    <>
      {routes ? (
        <>
          {routes ? (
            <>
              <ViewDetail
                user={user}
                userDel={userDel}
                setRoutes={setRoutes}
                setUserDel={setUserDel}
                setShow={setShow}
                setBlockUnblock={setBlockUnblock}
                blockUnblock={blockUnblock}
                tomiPrice={tomiPrice}
                usdPrice={usdPrice}
                btcPrice={btcPrice}
                pepePrice={pepePrice}
                linkPrice={linkPrice}
                uniPrice={uniPrice}
                dopPrice={dopPrice}
                gemsPrice={gemsPrice}
                privateSaleFilter={privateSaleFilter}
              />
            </>
          ) : (
            <section className="allagents">
              <div className="custom-container">
                <button
                  onClick={() => functiontogetback()}
                  class="btn-back guvxgyvxvtxx"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                      fill="black"
                    ></path>
                    <path
                      d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                      fill="black"
                    ></path>
                  </svg>{" "}
                  Back
                </button>
                {/* <div className="main-heading">
                  {user?.accessCode ? (
                    <h6>All Leaders ({user?.accessCode})</h6>
                  ) : (
                    <h6>All Leaders</h6>
                  )}
                  {user?.role !== "agent" && (
                    <button onClick={() => setShow1(true)} className="mega-btn">
                      Add Leader
                    </button>
                  )}
                </div> */}
                <div className="main-heading">
                  <div>
                <p className="selfhead">Premium Projects</p>
                  {user?.accessCode ? (
                    <h6>All Leaders ({user?.accessCode})</h6>
                  ) : (
                    <h6>All Leaders</h6>
                  )}
                  </div>
                  <div className="rightside-content">
                    <div className="custom-option-field">
                      <input
                        type="search"
                        placeholder="Search by wallet address..."
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />
                      <img
                        src="\assets\search-icon.svg"
                        alt="img"
                        className="img-fluid search-icon"
                      />
                    </div>
                    {user?.role == "mini_agent" ? (
                      ""
                    ) : (
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div
                            onClick={() => {
                              setRoleTypeFilter(roleTypeFilter);
                            }}
                          >
                            {roleTypeFilter == "god_agent"
                              ? "God Leader "
                              : roleTypeFilter == "mega_agent"
                              ? "Mega Leader "
                              : roleTypeFilter == "super_agent"
                              ? "Super Leader "
                              : roleTypeFilter == "agent"
                              ? "Leader "
                              : roleTypeFilter == "mini_agent"
                              ? "Ambassador "
                              : "Role "}
                          </div>
                          <img
                            src="\assets\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>
                        {user?.role == "admin" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("god_agent");
                                }}
                              >
                                God Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : user?.role == "god_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : user?.role == "mega_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : user?.role == "super_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : user?.role == "agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>

                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div
                          onClick={() => {
                            // setLeaderFilter("");
                          }}
                        >
                          {leaderFilter ? leaderFilter + " " : "Type "}

                          <img
                            src="\assets\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      </button>
                      <ul class="dropdown-menu">
                        {leaderNames?.length > 0
                          ? leaderNames?.map((item) => {
                              return (
                                <>
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      onClick={() => {
                                        setLeaderFilter(item?.name);
                                      }}
                                    >
                                      {item?.name}
                                    </a>
                                  </li>
                                </>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                    {/* <button
                      className="reset-btn-global"
                      onClick={() => {
                        setRoleTypeFilter("");
                        setLeaderFilter("");
                        setSearchQuery("");
                      }}
                    >
                      Reset
                    </button> */}
                  </div>
                </div>

                <DataTable
                  project={project}
                  btcPrice={btcPrice}
                  usdPrice={usdPrice}
                  tomiPrice={tomiPrice}
                  pepePrice={pepePrice}
                  linkPrice={linkPrice}
                  uniPrice={uniPrice}
                  dopPrice={dopPrice}
                  gemsPrice={gemsPrice}
                  setBlockUnblock={setBlockUnblock}
                  blockUnblock={blockUnblock}
                  setUserDel={setUserDel}
                  currentPage={currentPage}
                  GetAllRounds={GetAgents}
                  pages={pages}
                  leadersLoader={leadersLoader}
                  GetAgents={GetAgents}
                  page={page}
                  pageCount={pageCount}
                  offset={offset}
                  setOffset={setOffset}
                  agents={agents}
                  setAgents={setAgents}
                  setShow={setShow}
                  setRoutes={setRoutes}
                  setOrderDirection={setOrderDirection}
                  setOrderField={setOrderField}
                  handlePageChange={handlePageChange}
                  pagination={true}
                  tok={val}
                  searchBy={searchBy}
                  startDate={startDate}
                  endDate={endDate}
                  privateSaleFilter={privateSaleFilter}
                  selectedLeaders={selectedLeaders}
                  selectedRoles={selectedRoles}
                  leaderFilter={leaderFilter}
                  activeFilter={activeFilter}
                  inActiveFilter={inActiveFilter}
                  premiumFilter={premiumFilter}
                  searchQuery={searchQuery}
                  orderDirection={orderDirection}
                  orderField={orderField}
                />
              </div>
            </section>
          )}
          <Modal
            className="delete-modal global-modal-scss"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Block/Unlock{blockUnblock ? "Unblock" : "Block"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="delete-modal-div">
                <img
                  src="\assets\delete-modal-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
                <h6>
                  Are you sure you want to
                  {blockUnblock ? "Unblock" : "Block"} this?
                </h6>

                {/* <p>
                  This will delete it permanently. You cannot undo this action.
                </p> */}
              </div>
              <div className="twice-btn">
                <button onClick={() => handleClose()} className="btn-cancel">
                  Cancel
                </button>
                <button className="btn-del" onClick={() => DeleteUser()}>
                  {blockUnblock ? "Unblock" : "Block"}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="add-mega-modal global-modal-scss"
            show={show1}
            onHide={handleClose1}
            centered
          >
            <Modal.Header closeButton>
              {user?.role === "admin" ? (
                <Modal.Title>Add God Leader</Modal.Title>
              ) : (
                <Modal.Title>Add Leader</Modal.Title>
              )}
              {/* {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>}
                    {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>} */}
            </Modal.Header>
            <Modal.Body>
              <div className="add-mega-div">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Leader’s Name"
                    />
                    <label>Leader’s Name</label>
                  </div>
                </div>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      value={addr}
                      onChange={(e) => setAddr(e.target.value)}
                      placeholder="Enter Leader’s Wallet Address"
                    />
                    <label>Leader’s Wallet Address</label>
                  </div>
                </div>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="text"
                      placeholder="Enter Leader’s Email Address"
                    />
                    <label>Leader’s Email Address</label>
                  </div>
                </div>
                {user?.role !== "admin" && (
                  <div className="option-field">
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {role === null
                          ? "Type of Leader"
                          : convertToTitleCase(role)}
                      </button>
                      {user?.role === "god_agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("mega_agent")}>
                            <a class="dropdown-item" href="#">
                              Mega Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("super_agent")}>
                            <a class="dropdown-item" href="#">
                              Super Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("agent")}>
                            <a class="dropdown-item" href="#">
                              Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {user?.role === "mega_agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("super_agent")}>
                            <a class="dropdown-item" href="#">
                              Super Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("agent")}>
                            <a class="dropdown-item" href="#">
                              Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {user?.role === "super_agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("agent")}>
                            <a class="dropdown-item" href="#">
                              Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {user?.role === "agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  marginTop: -15,
                  marginBottom: 15,
                }}
              >
                {error}
              </p>
              <div className="twice-btn">
                <button
                  onClick={() => {
                    handleClose1();
                    setError("");
                  }}
                  className="btn-cancel"
                >
                  Back
                </button>
                <button
                  disabled={loader}
                  className="btn-add"
                  onClick={() => AddAgent()}
                >
                  {loader ? "Adding..." : "Add"}
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="add-mega-modal global-modal-scss"
            show={show2}
            onHide={handleClose2}
            centered
          >
            <Modal.Header closeButton>
              {user?.role === "admin" ? (
                <Modal.Title>Add Mega Leaders</Modal.Title>
              ) : (
                <Modal.Title>Add Leaders</Modal.Title>
              )}
            </Modal.Header>
            <Modal.Body>
              <div className="add-mega-div">
                {user?.role === "admin" ? (
                  <h6>God Leader has been added</h6>
                ) : (
                  <h6>{convertToTitleCase(role)} has been added</h6>
                )}
              </div>
              <div className="twice-btn">
                <button
                  onClick={() => handleClose2()}
                  className="btn-add"
                  style={{ flex: "auto" }}
                >
                  Okay
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <section className="allagents">
          <div className="custom-container">
            {/* <button
              class="btn-back guvxgyvxvtxx"
              onClick={() => functiontogetback()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                  fill="black"
                ></path>
                <path
                  d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                  fill="black"
                ></path>
              </svg>{" "}
              Back
            </button> */}
            {/* <div className="main-heading">
              {user?.accessCode ? (
                <h6>All Leaders ({user?.accessCode})</h6>
              ) : (
                <h6>All Leaders</h6>
              )}
              {user?.role !== "agent" && (
                <button onClick={() => setShow1(true)} className="mega-btn">
                  Add Leader
                </button>
              )}
            </div> */}
            <div className="main-heading">
              <div>
            <p className="selfhead">Premium Projects</p>
              {user?.accessCode ? (
                <h6>All Leaders ({user?.accessCode})</h6>
              ) : (
                <h6>All Leaders</h6>
              )}
              </div>
              <div className="multi-items">
                {user?.role == "god_agent" &&
                user?.specialAccess?.length > 0 ? (
                  <div className="set-twice-text">
                    <div className="parent-toggle-switch-custom">
                      <div class="custom-toggle">
                        <input
                          type="checkbox"
                          class="checkbox"
                          id="checkbox"
                          checked={myTree}
                          onChange={(e) => {
                            setMyTree(e.target.checked);
                          }}
                        />
                        <label class="switch" for="checkbox">
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                    <h6>Show data from my tree only</h6>
                  </div>
                ) : null}

                <div className="rightside-content">
                  <div className="custom-option-field">
                    <Dropdown className="positionset">
                      <Dropdown.Toggle id="dropdown-basic">
                        {searchBy == "accessCode"
                          ? "Access Code"
                          : searchBy == "walletAddress"
                          ? "Wallet"
                          : "By"}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="7"
                          viewBox="0 0 13 7"
                          fill="none"
                        >
                          <path
                            d="M0 0.5L2.22397e-07 3.0462L6.2 6.5L6.2 3.9538L0 0.5Z"
                            fill="#02171D"
                          />
                          <path
                            d="M12.3984 0.5L12.3984 3.0462L6.19844 6.5L6.19844 3.9538L12.3984 0.5Z"
                            fill="#02171D"
                          />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <a
                          className="dropdown-item drop"
                          onClick={() => setSearchBy("accessCode")}
                        >
                          Access Code
                        </a>
                        <a
                          className="dropdown-item drop"
                          onClick={() => setSearchBy("walletAddress")}
                        >
                          Wallet Address
                        </a>
                      </Dropdown.Menu>
                    </Dropdown>
                    <input
                      style={{ paddingLeft: "120px" }}
                      type="search"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      className="img-fluid search-icon"
                    >
                      <path
                        d="M10.0008 9.23257C9.37556 10.2088 8.2814 10.8558 7.03612 10.8558C5.09321 10.8558 3.51818 9.28077 3.51818 7.33787C3.51818 5.39497 5.09321 3.81994 7.03612 3.81994C8.97902 3.81994 10.5541 5.39497 10.5541 7.33787C10.5541 7.89647 10.4239 8.42465 10.1922 8.89374L12.5736 8.89374C12.7124 8.39889 12.7866 7.87705 12.7866 7.33787C12.7866 4.16198 10.212 1.5874 7.03612 1.5874C3.86022 1.5874 1.28564 4.16198 1.28564 7.33787C1.28564 10.5138 3.86022 13.0883 7.03612 13.0883C8.66134 13.0883 10.1291 12.4141 11.1749 11.3301L10.0008 9.23257Z"
                        fill="#0A2931"
                      />
                      <path
                        d="M14.9076 17.3347L15.636 14.6165L10.0373 9.16457L9.30901 11.8828L14.9076 17.3347Z"
                        fill="#0A2931"
                      />
                    </svg>
                  </div>

                  <button className="whitnewbtn" onClick={handleShow3}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.29339 15.1C6.97339 15.1 6.66006 15.02 6.36672 14.86C5.78006 14.5334 5.42672 13.94 5.42672 13.2734V9.74002C5.42672 9.40669 5.20672 8.90669 5.00006 8.65336L2.50672 6.01336C2.08672 5.59336 1.76672 4.87336 1.76672 4.33336V2.80002C1.76672 1.73336 2.57339 0.900024 3.60006 0.900024H12.4001C13.4134 0.900024 14.2334 1.72002 14.2334 2.73336V4.20002C14.2334 4.90002 13.8134 5.69336 13.4201 6.08669L10.5334 8.64002C10.2534 8.87336 10.0334 9.38669 10.0334 9.80002V12.6667C10.0334 13.26 9.66006 13.9467 9.19339 14.2267L8.27339 14.82C7.97339 15.0067 7.63339 15.1 7.29339 15.1ZM3.60006 1.90002C3.13339 1.90002 2.76672 2.29336 2.76672 2.80002V4.33336C2.76672 4.58002 2.96672 5.06002 3.22006 5.31336L5.76006 7.98669C6.10006 8.40669 6.43339 9.10669 6.43339 9.73336V13.2667C6.43339 13.7 6.73339 13.9134 6.86006 13.98C7.14006 14.1334 7.48006 14.1334 7.74006 13.9734L8.66672 13.38C8.85339 13.2667 9.04006 12.9067 9.04006 12.6667V9.80002C9.04006 9.08669 9.38672 8.30002 9.88672 7.88002L12.7401 5.35336C12.9667 5.12669 13.2401 4.58669 13.2401 4.19336V2.73336C13.2401 2.27336 12.8667 1.90002 12.4067 1.90002H3.60006Z"
                        fill="black"
                      />
                      <path
                        d="M3.99995 7.16664C3.90661 7.16664 3.81995 7.13997 3.73328 7.09331C3.49995 6.94664 3.42661 6.63331 3.57328 6.39997L6.85995 1.13331C7.00661 0.899972 7.31328 0.826639 7.54661 0.973306C7.77995 1.11997 7.85328 1.42664 7.70661 1.65997L4.41995 6.92664C4.32661 7.07997 4.16661 7.16664 3.99995 7.16664Z"
                        fill="black"
                      />
                    </svg>
                    filter
                  </button>
                </div>
              </div>
            </div>

            <DataTable
              project={project}
              btcPrice={btcPrice}
              usdPrice={usdPrice}
              tomiPrice={tomiPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
              gemsPrice={gemsPrice}
              setBlockUnblock={setBlockUnblock}
              blockUnblock={blockUnblock}
              setUserDel={setUserDel}
              currentPage={currentPage}
              GetAllRounds={GetAgents}
              pageCount={pageCount}
              pages={pages}
              GetAgents={GetAgents}
              leadersLoader={leadersLoader}
              page={page}
              offset={offset}
              setOffset={setOffset}
              agents={agents}
              setAgents={setAgents}
              setShow={setShow}
              setRoutes={setRoutes}
              setOrderDirection={setOrderDirection}
              setOrderField={setOrderField}
              handlePageChange={handlePageChange}
              pagination={true}
              tok={val}
              searchBy={searchBy}
              startDate={startDate}
              endDate={endDate}
              privateSaleFilter={privateSaleFilter}
              selectedLeaders={selectedLeaders}
              selectedRoles={selectedRoles}
              leaderFilter={leaderFilter}
              activeFilter={activeFilter}
              inActiveFilter={inActiveFilter}
              premiumFilter={premiumFilter}
              searchQuery={searchQuery}
              orderDirection={orderDirection}
              orderField={orderField}
            />
          </div>
        </section>
      )}
      <Modal
        className="delete-modal global-modal-scss"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{blockUnblock ? "Unblock" : "Block"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-modal-div">
            {blockUnblock ? (
              <img
                src="\assets\unblockuser.svg"
                alt="img"
                className="img-fluid"
              />
            ) : (
              <img
                src="\assets\blockuser.svg"
                alt="img"
                className="img-fluid"
              />
            )}
            <h6>
              Are you sure you want to {blockUnblock ? "Unblock" : "Block"}{" "}
              this?
            </h6>
            {/* <p>This will delete it permanently. You cannot undo this action.</p> */}
          </div>
          <div className="twice-btn">
            <button onClick={() => handleClose()} className="btn-cancel">
              Cancel
            </button>
            <button
              className={blockUnblock ? "btn-del btnunblockmodal" : "btn-del"}
              onClick={() => DeleteUser()}
            >
              {blockUnblock ? "Unblock" : "Block"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="add-mega-modal global-modal-scss"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          {user?.role === "admin" ? (
            <Modal.Title>Add God Leader</Modal.Title>
          ) : (
            <Modal.Title>Add Leader</Modal.Title>
          )}
          {/* {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>}
                    {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>} */}
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Leader’s Name"
                />
                {/* <label>Leader’s Name</label> */}
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  value={addr}
                  onChange={(e) => setAddr(e.target.value)}
                  placeholder="Enter Leader’s Wallet Address"
                />
                {/* <label>Leader’s Wallet Address</label> */}
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="text"
                  placeholder="Enter Leader’s Email Address"
                />
                {/* <label>Leader’s Email Address</label> */}
              </div>
            </div>
            {user?.role !== "admin" && (
              <div className="option-field">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {role === null
                      ? "Type of Leader"
                      : convertToTitleCase(role)}
                  </button>
                  {user?.role === "god_agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("mega_agent")}>
                        <a class="dropdown-item" href="#">
                          Mega Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("super_agent")}>
                        <a class="dropdown-item" href="#">
                          Super
                        </a>
                      </li>
                      <li onClick={() => setRole("agent")}>
                        <a class="dropdown-item" href="#">
                          Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("mini_agent")}>
                        <a class="dropdown-item" href="#">
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                  {user?.role === "mega_agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("super_agent")}>
                        <a class="dropdown-item" href="#">
                          Super
                        </a>
                      </li>
                      <li onClick={() => setRole("agent")}>
                        <a class="dropdown-item" href="#">
                          Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("mini_agent")}>
                        <a class="dropdown-item" href="#">
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                  {user?.role === "super_agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("agent")}>
                        <a class="dropdown-item" href="#">
                          Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("mini_agent")}>
                        <a class="dropdown-item" href="#">
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                  {user?.role === "agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("mini_agent")}>
                        <a class="dropdown-item" href="#">
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
          <p
            style={{
              color: "red",
              textAlign: "center",
              marginTop: -15,
              marginBottom: 15,
            }}
          >
            {error}
          </p>
          <div className="twice-btn">
            <button
              onClick={() => {
                handleClose1();
                setError("");
              }}
              className="btn-cancel"
            >
              Back
            </button>
            <button
              disabled={loader}
              className="btn-add"
              onClick={() => AddAgent()}
            >
              {loader ? "Adding..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          {user?.role === "admin" ? (
            <Modal.Title>Add Mega Leaders</Modal.Title>
          ) : (
            <Modal.Title>Add Leaders</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            {user?.role === "admin" ? (
              <h6>God Leader has been added</h6>
            ) : (
              <h6>
                {convertToTitleCase(role)}{" "}
                {convertToTitleCase(role) === "Leader"
                  ? "has been added"
                  : "Leader has been added"}{" "}
              </h6>
            )}
          </div>
          <div className="twice-btn">
            <button
              onClick={() => handleClose2()}
              className="btn-add"
              style={{ flex: "auto" }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas
        show={show3}
        onHide={handleClose3}
        placement="end"
        className="leadersfilter"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Dates
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M12.9182 0.178772H6.68816H1.07816C0.118161 0.178772 -0.361838 1.33877 0.318162 2.01877L5.49816 7.19877C6.32816 8.02877 7.67816 8.02877 8.50816 7.19877L10.4782 5.22877L13.6882 2.01877C14.3582 1.33877 13.8782 0.178772 12.9182 0.178772Z"
                  fill="black"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="cal set-custom-calendar-div">
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => {
                    // GetChartsDataCalendar([item.selection]);
                    // GetDashboardData([item.selection]);
                    formatDates([item.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <div className="spanmain">
            {startDate && endDate ? (
              <>
                <div
                  className="innerspan"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setStartDate("");
                  }}
                >
                  {moment(startDate)?.format("DD/MM/YYYY")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.14282 12.8571L12.8571 5.14282"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.8571 12.8571L5.14282 5.14282"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                {" to "}
                <div
                  className="innerspan"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEndDate("");
                  }}
                >
                  {moment(endDate)?.format("DD/MM/YYYY")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.14282 12.8571L12.8571 5.14282"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.8571 12.8571L5.14282 5.14282"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </div>

          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Projects & Rounds
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M12.9182 0.178772H6.68816H1.07816C0.118161 0.178772 -0.361838 1.33877 0.318162 2.01877L5.49816 7.19877C6.32816 8.02877 7.67816 8.02877 8.50816 7.19877L10.4782 5.22877L13.6882 2.01877C14.3582 1.33877 13.8782 0.178772 12.9182 0.178772Z"
                  fill="black"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Accordion defaultActiveKey="0">
                {roundsData.map((project, projectIndex) => (
                  <Accordion.Item
                    key={projectIndex}
                    eventKey={projectIndex.toString()}
                  >
                    <Accordion.Header>
                      <div className="maininnerbtn">
                        <label className="cyberpunk-checkbox-label">
                          <input
                            type="checkbox"
                            className="cyberpunk-checkbox"
                            checked={privateSaleFilter.some(
                              (item) =>
                                item.privateSale === project.privateSale &&
                                item.round.length === project.totalRounds
                            )}
                            onChange={() => {
                              // Check/uncheck all rounds
                              const allRoundsSelected = privateSaleFilter.some(
                                (item) =>
                                  item.privateSale === project.privateSale &&
                                  item.round.length === project.totalRounds
                              );
                              if (allRoundsSelected) {
                                project.rounds.forEach((round) => {
                                  handleCheckboxChangeRounds(
                                    project.privateSale,
                                    round
                                  );
                                });
                              } else {
                                for (let i = 1; i <= project.totalRounds; i++) {
                                  handleCheckboxChangeRounds(
                                    project.privateSale,
                                    i
                                  );
                                }
                              }
                            }}
                          />
                        </label>
                        <div className="projectmain">
                          <div className="projectmainimg">
                            <img
                              src={`/assets/${project.privateSale.toLowerCase()}.svg`}
                              alt="projectinnerimg"
                              className="projectinnerimg"
                            />
                          </div>
                          <p className="projectpara">{project.privateSale}</p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Array.from(
                        { length: project.totalRounds },
                        (_, roundIndex) => (
                          <div key={roundIndex} className="inneracc">
                            <label className="cyberpunk-checkbox-label">
                              <input
                                type="checkbox"
                                className="cyberpunk-checkbox"
                                checked={privateSaleFilter.some(
                                  (item) =>
                                    item.privateSale === project.privateSale &&
                                    item.round.includes(roundIndex + 1)
                                )}
                                onChange={() =>
                                  handleCheckboxChangeRounds(
                                    project.privateSale,
                                    roundIndex + 1
                                  )
                                }
                              />
                            </label>
                            <p className="inneracccpara">
                              Round {roundIndex + 1}
                            </p>
                          </div>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Dropdown.Menu>
          </Dropdown>
          <div className="spanmain">
            {privateSaleFilter.map((project) =>
              project.round.map((round, index) => {
                return (
                  <div
                    className="innerspan"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCheckboxChangeRounds(project.privateSale, round);
                    }}
                  >
                    {project.privateSale} Round {round}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M5.14282 12.8571L12.8571 5.14282"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8571 12.8571L5.14282 5.14282"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                );
              })
            )}
          </div>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              By Leader Name
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M12.9182 0.178772H6.68816H1.07816C0.118161 0.178772 -0.361838 1.33877 0.318162 2.01877L5.49816 7.19877C6.32816 8.02877 7.67816 8.02877 8.50816 7.19877L10.4782 5.22877L13.6882 2.01877C14.3582 1.33877 13.8782 0.178772 12.9182 0.178772Z"
                  fill="black"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="searchinput">
                <input
                  type="text"
                  placeholder="Search"
                  className="innerinput"
                  value={searchInputLeaders}
                  onChange={handleSearchChangeLeaders}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="searchimg"
                >
                  <path
                    d="M7.66671 14.5002C3.90004 14.5002 0.833374 11.4335 0.833374 7.66683C0.833374 3.90016 3.90004 0.833496 7.66671 0.833496C11.4334 0.833496 14.5 3.90016 14.5 7.66683C14.5 11.4335 11.4334 14.5002 7.66671 14.5002ZM7.66671 1.8335C4.44671 1.8335 1.83337 4.4535 1.83337 7.66683C1.83337 10.8802 4.44671 13.5002 7.66671 13.5002C10.8867 13.5002 13.5 10.8802 13.5 7.66683C13.5 4.4535 10.8867 1.8335 7.66671 1.8335Z"
                    fill="#929292"
                  />
                  <path
                    d="M14.6666 15.1666C14.54 15.1666 14.4133 15.12 14.3133 15.02L12.98 13.6866C12.7866 13.4933 12.7866 13.1733 12.98 12.98C13.1733 12.7866 13.4933 12.7866 13.6866 12.98L15.02 14.3133C15.2133 14.5066 15.2133 14.8266 15.02 15.02C14.92 15.12 14.7933 15.1666 14.6666 15.1666Z"
                    fill="#929292"
                  />
                </svg>
              </div>
              <Accordion defaultActiveKey={allEventKeys}>
                {renderHierarchy(leaderNames2)}
              </Accordion>
            </Dropdown.Menu>
          </Dropdown>
          <div className="spanmain">
            {selectedLeaders?.length > 0
              ? selectedLeaders?.map((item) => {
                  return (
                    <div
                      className="innerspan"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleCheckboxChangeLeaders(item);
                      }}
                    >
                      {item?.name}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M5.14282 12.8571L12.8571 5.14282"
                          stroke="white"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8571 12.8571L5.14282 5.14282"
                          stroke="white"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  );
                })
              : null}
          </div>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Role
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M12.9182 0.178772H6.68816H1.07816C0.118161 0.178772 -0.361838 1.33877 0.318162 2.01877L5.49816 7.19877C6.32816 8.02877 7.67816 8.02877 8.50816 7.19877L10.4782 5.22877L13.6882 2.01877C14.3582 1.33877 13.8782 0.178772 12.9182 0.178772Z"
                  fill="black"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <div className="searchinput">
                <input
                  type="text"
                  placeholder="Search"
                  className="innerinput"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="searchimg"
                >
                  <path
                    d="M7.66671 14.5002C3.90004 14.5002 0.833374 11.4335 0.833374 7.66683C0.833374 3.90016 3.90004 0.833496 7.66671 0.833496C11.4334 0.833496 14.5 3.90016 14.5 7.66683C14.5 11.4335 11.4334 14.5002 7.66671 14.5002ZM7.66671 1.8335C4.44671 1.8335 1.83337 4.4535 1.83337 7.66683C1.83337 10.8802 4.44671 13.5002 7.66671 13.5002C10.8867 13.5002 13.5 10.8802 13.5 7.66683C13.5 4.4535 10.8867 1.8335 7.66671 1.8335Z"
                    fill="#929292"
                  />
                  <path
                    d="M14.6666 15.1666C14.54 15.1666 14.4133 15.12 14.3133 15.02L12.98 13.6866C12.7866 13.4933 12.7866 13.1733 12.98 12.98C13.1733 12.7866 13.4933 12.7866 13.6866 12.98L15.02 14.3133C15.2133 14.5066 15.2133 14.8266 15.02 15.02C14.92 15.12 14.7933 15.1666 14.6666 15.1666Z"
                    fill="#929292"
                  />
                </svg>
              </div> */}
              <div className="inneritem">
                <label className="cyberpunk-checkbox-label">
                  <input
                    type="checkbox"
                    className="cyberpunk-checkbox"
                    checked={isAllRolesSelected}
                    onChange={() => handleCheckboxChange("all")}
                  />
                </label>
                <p className="inneracccpara">All Roles</p>
              </div>

              {roles.map((role) => (
                <div className="inneritem" key={role}>
                  <label className="cyberpunk-checkbox-label">
                    <input
                      type="checkbox"
                      className="cyberpunk-checkbox"
                      checked={selectedRoles.includes(role)}
                      onChange={() => handleCheckboxChange(role)}
                    />
                  </label>
                  <p className="inneracccpara"> {convertToTitleCase(role)}</p>
                </div>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <div className="spanmain">
            {selectedRoles?.length > 0
              ? selectedRoles?.map((item) => {
                  return (
                    <div
                      className="innerspan"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleCheckboxChange(item);
                      }}
                    >
                      {convertToTitleCase(item)}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M5.14282 12.8571L12.8571 5.14282"
                          stroke="white"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8571 12.8571L5.14282 5.14282"
                          stroke="white"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  );
                })
              : null}
          </div>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Activity
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M12.9182 0.178772H6.68816H1.07816C0.118161 0.178772 -0.361838 1.33877 0.318162 2.01877L5.49816 7.19877C6.32816 8.02877 7.67816 8.02877 8.50816 7.19877L10.4782 5.22877L13.6882 2.01877C14.3582 1.33877 13.8782 0.178772 12.9182 0.178772Z"
                  fill="black"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div
                className="inneritem"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveFilter(false);
                  setInActiveFilter(false);
                }}
              >
                <p className="inneracccpara">All</p>
              </div>
              <div
                className="inneritem"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveFilter(true);
                  setInActiveFilter(false);
                }}
              >
                <p className="inneracccpara">Active</p>
              </div>
              <div
                className="inneritem"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveFilter(false);
                  setInActiveFilter(true);
                }}
              >
                <p className="inneracccpara">Inactive</p>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <div className="spanmain">
            {activeFilter == true ? (
              <div
                className="innerspan"
                style={{ cursor: "pointer" }}
                onClick={() => setActiveFilter(false)}
              >
                Active
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5.14282 12.8571L12.8571 5.14282"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.8571 12.8571L5.14282 5.14282"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            ) : inActiveFilter == true ? (
              <div
                className="innerspan"
                style={{ cursor: "pointer" }}
                onClick={() => setInActiveFilter(false)}
              >
                Inactive
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5.14282 12.8571L12.8571 5.14282"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.8571 12.8571L5.14282 5.14282"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            ) : null}
          </div>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Premium Status
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M12.9182 0.178772H6.68816H1.07816C0.118161 0.178772 -0.361838 1.33877 0.318162 2.01877L5.49816 7.19877C6.32816 8.02877 7.67816 8.02877 8.50816 7.19877L10.4782 5.22877L13.6882 2.01877C14.3582 1.33877 13.8782 0.178772 12.9182 0.178772Z"
                  fill="black"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div
                className="inneritem"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPremiumFilter();
                }}
              >
                <p className="inneracccpara">All</p>
              </div>
              <div
                className="inneritem"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPremiumFilter(true);
                }}
              >
                <p className="inneracccpara">Premium</p>
              </div>
              <div
                className="inneritem"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPremiumFilter(false);
                }}
              >
                <p className="inneracccpara">Basic</p>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <div className="spanmain">
            {premiumFilter == true ? (
              <div
                className="innerspan"
                style={{ cursor: "pointer" }}
                onClick={() => setPremiumFilter()}
              >
                Premium
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5.14282 12.8571L12.8571 5.14282"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.8571 12.8571L5.14282 5.14282"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            ) : premiumFilter == false ? (
              <div
                className="innerspan"
                style={{ cursor: "pointer" }}
                onClick={() => setPremiumFilter()}
              >
                Basic
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5.14282 12.8571L12.8571 5.14282"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.8571 12.8571L5.14282 5.14282"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            ) : null}
          </div>
          <div className="bottombtns">
            <button
              className="whitebtn"
              onClick={() => {
                // setSearch("");
                // setRoundFilter([]);
                // setLeaderNameFilter("");
                setLeaderFilter("");
                setRoleTypeFilter("");
                // setSaleTypeFilter("");
                setPrivateSaleFilter([]);
                setSelectedLeaders([]);
                setSelectedRoles([]);
                setLeaderFilter("");
                setPremiumFilter();
                setActiveFilter();
                setInActiveFilter(false);
              }}
            >
              Clear all
            </button>
            <button
              className="greybtn"
              onClick={() => {
                GetAgents();
                handleClose3();
              }}
            >
              Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AllAgents;
