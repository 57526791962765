import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import {
  getPreSaleContract,
  getGEMSPreSaleContract,
  getLEIAPreSaleContract,
  getOlympusPreSaleContract,
  getNFAPreSaleContract,
  getAstroNovaPreSaleContract,
} from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const Timer = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.PreSale;
  const incentivContractPresale = getPreSaleContract(tokenAddress, web3);

  const tokenAddressLEIA = Environment.LEIAPreSale;
  const LEIAContractPresale = getLEIAPreSaleContract(tokenAddressLEIA, web3);

  const tokenAddressGEMS = Environment.GemsPreSale;
  const GEMSContractPresale = getGEMSPreSaleContract(tokenAddressGEMS, web3);

  const tokenAddressOlympus = Environment.OlympusPreSale;
  const OlympusContractPresale = getOlympusPreSaleContract(
    tokenAddressOlympus,
    web3
  );

  const tokenAddressNFA = Environment.NFAPreSale;
  const NFAContractPresale = getNFAPreSaleContract(tokenAddressNFA, web3);

  const tokenAddressAstroNova = Environment.AstroNovaPreSale;
  const AstroNovaContractPresale = getAstroNovaPreSaleContract(
    tokenAddressAstroNova,
    web3
  );

  const project = localStorage.getItem("project");

  const timer = useCallback(
    async (e) => {
      if (project == "INCENTIV") {
        try {
          const buy = await incentivContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          const buy = await OlympusContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "LEIA") {
        try {
          const buy = await LEIAContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "NFA") {
        try {
          const buy = await NFAContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "ASTRANOVA") {
        try {
          const buy = await AstroNovaContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "GEMS") {
        try {
          const buy = await GEMSContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      }
    },
    [LEIAContractPresale, incentivContractPresale, account, web3]
  );
  return { timer: timer };
};
export default Timer;
