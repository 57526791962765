import React, { useState } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Detaildatatable = ({
  setRoutes,

  transactions,
  usdPrice,
  user,
  setTransactions,
  setOrderDirection,
  setOrderField,
}) => {
  const [copy3, setCopy3] = useState(false);

  const textCopiedFun3 = (index) => {
    setCopy3(index);

    setTimeout(() => {
      setCopy3(false);
    }, 1000);
  };

  function formatTokenValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(2) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue?.toFixed(2);
    }
  }
  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date
                    <div className="filter-btn" style={{ cursor: "pointer" }}>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>Sale Type</th>
                <th>Referral Link</th>
                <th>Country</th>

                <th>Hash</th>
                <th>Wallet Address</th>
                <th>Price</th>
                <th>Paid</th>
                <th>Amount</th>
                <th>Payment</th>
                <th>Payment Type</th>
              </tr>
            </thead>
            <tbody>
              {console.log(
                "transactionstransactionstransactions",
                transactions
              )}
              {transactions?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="twice-text">
                        <>
                          <h6>
                            {moment(item?.transactionTime).format("DD/MM/YYYY")}
                          </h6>
                          <p>{moment(item?.transactionTime).format("hh:mm")}</p>
                        </>
                      </div>
                    </td>
                    <td>Referrals</td>
                    <td>
                      <p className="codepara">
                        <p>
                          gems.vip/{item?.referral[0]?.referralCode}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                          >
                            <path
                              d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                              stroke="#77868B"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                              stroke="#77868B"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </p>
                      </p>
                    </td>
                    <td>{item?.country}</td>

                    <td>
                      <p className="codepara">
                        {/* <p className="codepara"> */}
                        {/* <CopyToClipboard
                text={item?.transactionHash}
                onCopy={(e) => textCopiedFun2(index)}
              > */}
                        <p>
                          {item?.transactionHash?.slice(0, 3)}...
                          {item?.transactionHash?.slice(
                            item?.transactionHash?.length - 3,
                            item?.transactionHash?.length
                          )}
                          <a
                            target="_blank"
                            href={`https://etherscan.io/tx/${item?.transactionHash}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14"
                              viewBox="0 0 15 14"
                              fill="none"
                            >
                              <path
                                d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                                stroke="#77868B"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </p>
                        {/* </CopyToClipboard> */}
                        {/* {copy2 === index && <p className="copy-text">Copied</p>} */}
                      </p>

                      {/* <p>
                          0x524...t56
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                          >
                            <path
                              d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                              stroke="#77868B"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </p> */}
                      {/* </p> */}
                    </td>
                    <td>
                      <p className="codepara">
                        <CopyToClipboard
                          text={item?.by}
                          onCopy={(e) => textCopiedFun3(index)}
                        >
                          <p className="adadasdasdasdasdasdsad">
                            {item?.by.slice(0, 4)}...
                            {item?.by.slice(
                              item?.by.length - 3,
                              item?.by.length
                            )}
                            <svg
                              style={{ cursor: "pointer" }}
                              className={copy3 === index && "copy-button "}
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <path
                                d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                                stroke="#77868B"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                                stroke="#77868B"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            {copy3 === index && (
                              <p className="copy-text">Copied</p>
                            )}
                          </p>
                        </CopyToClipboard>
                        {/* <p>
                          0x524...t56
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                          >
                            <path
                              d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                              stroke="#77868B"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                              stroke="#77868B"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </p> */}
                      </p>
                    </td>

                    <td>{item?.price}</td>
                    <td>${item?.totalSaleConverted.toFixed(2)}</td>
                    <td>
                      {formatTokenValue(item?.dopPurchased)} {item?.privateSale}
                    </td>
                    <td>
                      {item?.amountInvested} {item?.tokenSelected}
                    </td>
                    <td>{item?.tokenSelected}</td>
                  </tr>
                );
              })}

              {/* <tr>
                <td>
                  <div className="twice-text">
                    <>
                      <h6>03/09/2024</h6>
                      <p>5:14</p>
                    </>
                  </div>
                </td>
                <td>Referrals</td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/a...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>Spain</td>

                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>

                <td>0.07</td>
                <td>$140.00</td>
                <td>2.00K TOKEN</td>
                <td>140.00 USDT</td>
                <td>ETH</td>
              </tr>
              <tr>
                <td>
                  <div className="twice-text">
                    <>
                      <h6>03/09/2024</h6>
                      <p>5:14</p>
                    </>
                  </div>
                </td>
                <td>Referrals</td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/a...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>Spain</td>

                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>

                <td>0.07</td>
                <td>$140.00</td>
                <td>2.00K TOKEN</td>
                <td>140.00 USDT</td>
                <td>ETH</td>
              </tr>
              <tr>
                <td>
                  <div className="twice-text">
                    <>
                      <h6>03/09/2024</h6>
                      <p>5:14</p>
                    </>
                  </div>
                </td>
                <td>Referrals</td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/a...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>Spain</td>

                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>

                <td>0.07</td>
                <td>$140.00</td>
                <td>2.00K TOKEN</td>
                <td>140.00 USDT</td>
                <td>ETH</td>
              </tr>
              <tr>
                <td>
                  <div className="twice-text">
                    <>
                      <h6>03/09/2024</h6>
                      <p>5:14</p>
                    </>
                  </div>
                </td>
                <td>Referrals</td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/a...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>Spain</td>

                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>

                <td>0.07</td>
                <td>$140.00</td>
                <td>2.00K TOKEN</td>
                <td>140.00 USDT</td>
                <td>ETH</td>
              </tr>
              <tr>
                <td>
                  <div className="twice-text">
                    <>
                      <h6>03/09/2024</h6>
                      <p>5:14</p>
                    </>
                  </div>
                </td>
                <td>Referrals</td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/a...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>Spain</td>

                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>

                <td>0.07</td>
                <td>$140.00</td>
                <td>2.00K TOKEN</td>
                <td>140.00 USDT</td>
                <td>ETH</td>
              </tr>
              <tr>
                <td>
                  <div className="twice-text">
                    <>
                      <h6>03/09/2024</h6>
                      <p>5:14</p>
                    </>
                  </div>
                </td>
                <td>Referrals</td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/a...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>Spain</td>

                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>

                <td>0.07</td>
                <td>$140.00</td>
                <td>2.00K TOKEN</td>
                <td>140.00 USDT</td>
                <td>ETH</td>
              </tr>
              <tr>
                <td>
                  <div className="twice-text">
                    <>
                      <h6>03/09/2024</h6>
                      <p>5:14</p>
                    </>
                  </div>
                </td>
                <td>Referrals</td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/a...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>Spain</td>

                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>

                <td>0.07</td>
                <td>$140.00</td>
                <td>2.00K TOKEN</td>
                <td>140.00 USDT</td>
                <td>ETH</td>
              </tr>
              <tr>
                <td>
                  <div className="twice-text">
                    <>
                      <h6>03/09/2024</h6>
                      <p>5:14</p>
                    </>
                  </div>
                </td>
                <td>Referrals</td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/a...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>Spain</td>

                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>

                <td>0.07</td>
                <td>$140.00</td>
                <td>2.00K TOKEN</td>
                <td>140.00 USDT</td>
                <td>ETH</td>
              </tr>
              <tr>
                <td>
                  <div className="twice-text">
                    <>
                      <h6>03/09/2024</h6>
                      <p>5:14</p>
                    </>
                  </div>
                </td>
                <td>Referrals</td>
                <td>
                  <p className="codepara">
                    <p>
                      gems.vip/a...
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>Spain</td>

                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>
                <td>
                  <p className="codepara">
                    <p>
                      0x524...t56
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M9.41377 8.49972L9.44015 10.9496C9.46213 12.9911 8.65431 13.8165 6.61276 13.8385L4.1629 13.8649C2.12135 13.8869 1.29594 13.0791 1.27396 11.0375L1.24757 8.58766C1.22559 6.54611 2.03342 5.7207 4.07496 5.69871L6.52482 5.67233C8.56637 5.65035 9.39178 6.45817 9.41377 8.49972Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8759 4.96213L12.9023 7.41198C12.9243 9.45353 12.1165 10.2789 10.0749 10.3009L9.43329 10.3078L9.41382 8.49961C9.39183 6.45806 8.56642 5.65024 6.52487 5.67222L4.71664 5.69169L4.70973 5.05006C4.68775 3.00851 5.49557 2.1831 7.53712 2.16112L9.98698 2.13474C12.0285 2.11275 12.8539 2.92058 12.8759 4.96213Z"
                          stroke="#77868B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </p>
                  </p>
                </td>

                <td>0.07</td>
                <td>$140.00</td>
                <td>2.00K TOKEN</td>
                <td>140.00 USDT</td>
                <td>ETH</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="footer-content mblviewdisplaynone mt-2">
          <div className="left-f">
            {/* <h6>SHOWING 1-10 OF 145</h6> */}
            <h6>SHOWING 1-10 OF 145</h6>
          </div>
          <div className="right-f">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <nav aria-label="Page navigation example">
                  <ul class="text-center">
                    <ReactPaginate
                      previousLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z"
                            fill="#329879"
                          />
                          <path
                            d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z"
                            fill="#329879"
                          />
                          <path
                            d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z"
                            fill="#329879"
                          />
                        </svg>
                      }
                      nextLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z"
                            fill="#329879"
                          />
                          <path
                            d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z"
                            fill="#329879"
                          />
                          <path
                            d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z"
                            fill="#329879"
                          />
                        </svg>
                      }
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      containerClassName="pagination"
                      activeClassName="active"
                    ></ReactPaginate>
                  </ul>
                </nav>
              </ul>
            </nav>
          </div>
        </div>
        <div className="mobiledesignss  d-none">
          <div className="new-accordionmobile dsuygfdsyufgsdyuf d-none">
            <div className="upper-body">
              <div className="inner-text">
                <h6>Date</h6>
                <p>03/09/2024</p>
              </div>
              <div className="inner-text">
                <h6>Sale Type</h6>
                <p>Referrals</p>
              </div>
              <div className="inner-text">
                <h6>Referral Link</h6>
                <p>
                  gems.vip/a...{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M10.3027 8.49972L10.3291 10.9496C10.351 12.9911 9.54322 13.8165 7.50168 13.8385L5.05182 13.8649C3.01027 13.8869 2.18486 13.0791 2.16287 11.0375L2.13649 8.58766C2.11451 6.54611 2.92233 5.7207 4.96388 5.69871L7.41374 5.67233C9.45529 5.65035 10.2807 6.45817 10.3027 8.49972Z"
                      stroke="#77868B"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.7648 4.96237L13.7912 7.41223C13.8132 9.45378 13.0054 10.2792 10.9638 10.3012L10.3222 10.3081L10.3027 8.49985C10.2807 6.45831 9.45534 5.65048 7.41379 5.67246L5.60556 5.69194L5.59865 5.05031C5.57666 3.00876 6.38449 2.18335 8.42604 2.16136L10.8759 2.13498C12.9174 2.113 13.7429 2.92082 13.7648 4.96237Z"
                      stroke="#77868B"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </div>
              <div className="inner-text">
                <h6>Country</h6>
                <p>Spain</p>
              </div>

              <div className="inner-text">
                <h6>Hash</h6>
                <p className="adadasdasdasdasdasdsad">
                  0x524...t56
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                  >
                    <path
                      d="M6.16678 2.91659H5.11678C4.46339 2.91659 4.13645 2.91659 3.88688 3.04374C3.66736 3.1556 3.48901 3.33394 3.37716 3.55347C3.25 3.80303 3.25 4.12997 3.25 4.78337V9.2167C3.25 9.87009 3.25 10.1966 3.37716 10.4462C3.48901 10.6657 3.66736 10.8444 3.88688 10.9562C4.1362 11.0833 4.46275 11.0833 5.11486 11.0833H9.5518C10.2039 11.0833 10.53 11.0833 10.7793 10.9562C10.9988 10.8444 11.1778 10.6655 11.2896 10.446C11.4167 10.1967 11.4167 9.8705 11.4167 9.21839V8.16659M12 5.24992V2.33325M12 2.33325H9.08333M12 2.33325L7.91667 6.41659"
                      stroke="#77868B"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </div>
              <div className="inner-text">
                <h6>Wallet Address</h6>
                <p className="adadasdasdasdasdasdsad">
                  0x524...t56
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                  >
                    <path
                      d="M10.1916 8.49972L10.218 10.9496C10.24 12.9911 9.43214 13.8165 7.39059 13.8385L4.94073 13.8649C2.89918 13.8869 2.07377 13.0791 2.05179 11.0375L2.02541 8.58766C2.00342 6.54611 2.81125 5.7207 4.8528 5.69871L7.30265 5.67233C9.3442 5.65035 10.1696 6.45817 10.1916 8.49972Z"
                      stroke="#77868B"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.6538 4.96237L13.6801 7.41223C13.7021 9.45378 12.8943 10.2792 10.8527 10.3012L10.2111 10.3081L10.1916 8.49985C10.1697 6.45831 9.34425 5.65048 7.3027 5.67246L5.49447 5.69194L5.48756 5.05031C5.46558 3.00876 6.27341 2.18335 8.31495 2.16136L10.7648 2.13498C12.8064 2.113 13.6318 2.92082 13.6538 4.96237Z"
                      stroke="#77868B"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </div>
            </div>
            <div className="bottom-body">
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>
                    More Info{" "}
                    <img
                      src="\assets\arrow-down.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="inner-textt">
                      <p>Price</p>

                      <h6 className="textright">0.07</h6>
                    </div>
                    <div className="inner-textt">
                      <p>Paid</p>
                      <h6 className="textright">$140.00</h6>
                    </div>
                    <div className="inner-textt">
                      <p>Amount</p>
                      <h6 className="textright">2.00K TOKEN</h6>
                    </div>
                    <div className="inner-textt">
                      <p>Payment</p>
                      <h6 className="textright">140.00 USDT</h6>
                    </div>
                    <div className="inner-textt">
                      <p>Payment Type</p>
                      <h6 className="textright">ETH</h6>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="footer-content mt-2">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M7.38916 1.93555L4.88776 1.93555L1.49471 7.99682L3.99612 7.99682L7.38916 1.93555Z"
                              fill="#329879"
                            />
                            <path
                              d="M7.38916 14.0703L4.88776 14.0703L1.49471 8.00904L3.0712 6.96117L3.99612 8.00904L7.38916 14.0703Z"
                              fill="#329879"
                            />
                            <path
                              d="M7.51642 6.85742H14.4615L13.1868 9.14313H6.2417L7.51642 6.85742Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8.61084 1.93555L11.1122 1.93555L14.5053 7.99682L12.0039 7.99682L8.61084 1.93555Z"
                              fill="#329879"
                            />
                            <path
                              d="M8.61084 14.0703L11.1122 14.0703L14.5053 8.00904L12.9288 6.96117L12.0039 8.00904L8.61084 14.0703Z"
                              fill="#329879"
                            />
                            <path
                              d="M2.81306 6.85742H9.75811L8.48338 9.14313H1.53833L2.81306 6.85742Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName="pagination"
                        activeClassName="active"
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Detaildatatable;
