import React, { useState } from "react";
import { Api_Url } from "../../utils/ApiUrl";
import "./login.scss";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";

const Login = () => {
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const userLogin = async () => {
    const lowerCaseEmail = userName.toLowerCase();
    localStorage.removeItem("indexvalue");
    if (ValidateForm()) {
      const data = {
        email: lowerCaseEmail,
        password: password,
        rememberMe: false,
      };
      setLoader(true);
      await axios
        .post(`${Api_Url}/auth/users/admin-signin`, data)
        .then((res) => {
          const resData = res?.data?.data;
          localStorage.setItem("userId", JSON.stringify(resData?.user));
          localStorage?.setItem("accessToken", resData?.accessToken);
          localStorage?.setItem("refreshToken", resData?.refreshToken);
          localStorage?.setItem("email", resData?.admin?.email);
          localStorage?.setItem("id", resData?.admin?._id);
          localStorage?.setItem("user", JSON.stringify(resData?.user));
          setLoader(false);
          history.push("/");
        })
        .catch((err) => {
          if (
            err?.response?.data?.statusCode == 403
            // &&
            // err?.response?.data?.message == "You are blocked"
          ) {
            toast.error(err?.response?.data.message, {
              position: "top-right",
              autoClose: 2000,
            });
            // setShow222(true);
          } else {
            toast.error(err?.response?.data.message, {
              position: "top-right",
              autoClose: 2000,
            });
          }
          console.log("err response: ", err?.response);
          setLoader(false);
          // setError(err?.response?.data?.message);
        });
    }
  };

  const ValidateForm = () => {
    // let input = userInput;
    let errors = {};
    let isValid = true;

    if (!userName) {
      isValid = false;
      errors["username"] = "*Please enter a username";
    }

    if (!password) {
      isValid = false;
      errors["password"] = "*Please enter a password";
    }

    setErrors(errors);
    return isValid;
  };

  const [show222, setShow222] = useState(false);
  const handleClose222 = () => setShow222(false);

  return (
    <>
      <section className="login">
        <img src="/leftshade.png" alt="leftshade" className="leftshade" />
        <img src="/midshade.png" alt="leftshade" className="midshade" />
        <img
          src="/leftshadembl.png"
          alt="leftshade"
          className="leftshadembl d-none"
        />
        <img
          src="/rightshadembl.png"
          alt="leftshade"
          className="midshadembl d-none"
        />
        <div className="loginheader">
          <div className="custom-container">
            <img src="/logo.svg" alt="logoimg" className="logoimg" />
          </div>
        </div>
        <div className="login-card">
          <img src="\loginlogo.svg" alt="img" className="img-fluid" />
          <h6>log into your admin account</h6>
          <div className="option-field">
            <div className="material-textfield">
              <input
                type="text"
                placeholder="Enter your email address"
                value={userName}
                onChange={(e) => {
                  setUserName(e?.target?.value);
                  let tempErrors = errors;
                  tempErrors["username"] = "";
                  setErrors(errors);
                }}
              />
              {/* <label>Email</label> */}
            </div>
          </div>
          <div className="option-field">
            <div className="material-textfield">
              <input
                placeholder="Enter your password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e?.target?.value);
                  let tempErrors = errors;
                  tempErrors["password"] = "";
                  setErrors(tempErrors);
                  setError("");
                }}
              />
              {/* <label>Password</label> */}
            </div>
            <img
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: "pointer" }}
              src="\eye.svg"
              alt="img"
              className="img-fluid password-icon"
            />
          </div>
          <div className="custom-checkbox">
            <div class="form-group">
              {/* <input type="checkbox" id="html" />
                            <label for="html">Keep me logged in</label> */}
            </div>
            <Link to="/forgot">Forgot Password?</Link>
          </div>
          <div style={{ position: "unset" }} className="text-danger mb-3">
            <small>{errors.username}</small>
          </div>
          <div style={{ position: "unset" }} className="text-danger mb-3">
            <small>{errors.password}</small>
          </div>
          <button
            disabled={loader}
            onClick={() => userLogin()}
            className="btn-login"
          >
            {loader ? "Logging In..." : "Log In"}
          </button>
        </div>
      </section>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show222}
        onHide={handleClose222}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Blocked</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="blockuser-modal">
            <h6>
              This account has violated our terms of use and been blocked.
            </h6>
            <a href="mailto: support@dop.org">support@gems.org</a>
          </div>
          <div className="twice-btn">
            <button
              onClick={() => handleClose222()}
              className="btn-add"
              style={{ flex: "auto" }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
