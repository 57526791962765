import React, { useState, useEffect } from "react";
import "./alltransaction.scss";
import DataTable from "./DataTable/DataTable";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Api_Url_Referral } from "../../../utils/ApiUrl";
import { useHistory } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

const AllTransaction = ({
  user,
  setindexwait,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
}) => {
  const [codeDataCall, setCodeDataCall] = useState();
  const tok = localStorage?.getItem("accessToken");
  const project = localStorage.getItem("project");

  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderNames2, setLeaderNames2] = useState([]);

  const [transactionApiCalled, setTransactionApiCalled] = useState(false);
  const [data, setData] = useState([]);
  const [totalT, setTotalT] = useState(0);
  const [purchases, setPurchases] = useState(null);
  const [dops, setDops] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [total, setTotal] = useState(0);
  // const [usdPrice, setUsdPrice] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [code, setCode] = useState([]);
  const [pc, setPC] = useState(0);
  const [pmc, setPMC] = useState(0);
  const [nrc, setNRC] = useState(0);
  const [lc, setLC] = useState(0);
  const [dpc, setDPC] = useState(0);
  const [lec, setLEC] = useState(0);
  const [mm, setmm] = useState(0);
  const [ss, setss] = useState(0);
  const [gg, setgg] = useState(0);
  const [pee, setPEE] = useState(0);
  const [peu, setPEU] = useState(0);
  const [pet, setPET] = useState(0);
  const [peUsdc, setPEUsdc] = useState(0);

  const [peBtc, setPEBTC] = useState(0);
  const [pePepe, setPEPepe] = useState(0);
  const [peLink, setPELink] = useState(0);
  const [peUni, setPEUni] = useState(0);
  const [peDop, setPEDop] = useState(0);

  const [pdp, setPDP] = useState(0);
  const [ind, setIND] = useState(0);
  const [dt, setDT] = useState(0);
  const [adminobj, setadminobj] = useState();
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("transactionHashFilter");
  const [myTree, setMyTree] = useState(false);
  const [tokenNftStats, setTokenNftStats] = useState();

  const [typeFilter, setTypeFilter] = useState("");
  const [orderField, setOrderField] = useState("transactionTime");
  const [orderDirection, setOrderDirection] = useState("-1");
  const history = useHistory();

  // const [tomiPrice, setTomiPrice] = useState("");
  const userObj = JSON.parse(localStorage.getItem("user"));
  const [roundsData, setRoundsData] = useState([]);

  const [privateSaleFilter, setPrivateSaleFilter] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedLeaders, setSelectedLeaders] = useState([]);
  const [walletAndCode, setWalletAndCode] = useState([]);
  const [selectedWallets, setSelectedWallets] = useState([]);
  const [selectedAccessCodes, setSelectedAccessCodes] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputAccessCodes, setSearchInputAccessCodes] = useState("");

  const [filteredWallets, setFilteredWallets] = useState([]);
  const [filteredAccessCodes, setFilteredAccessCodes] = useState([]);

  const [searchInputLeaders, setSearchInputLeaders] = useState("");

  const flattenNestedArrays = (nestedArray) => {
    let result = [];

    const flatten = (array) => {
      array.forEach((item) => {
        result.push(item);

        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key]) && item[key].length > 0) {
            flatten(item[key]);
          }
        });
      });
    };

    flatten(nestedArray);
    return result;
  };
  const handleSearchChangeLeaders = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInputLeaders(searchValue);

    if (searchValue === "") {
      // Restore the original data when search is cleared
      setLeaderNames2(leaderNames);
    } else {
      const flattened = flattenNestedArrays(leaderNames); // Flatten the original data for searching
      const filtered = flattened.filter((item) =>
        item.name.toLowerCase().includes(searchValue)
      );
      setLeaderNames2(filtered);
    }
  };

  const handleSearchChangeWallet = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInput(searchValue);

    if (searchValue === "") {
      setFilteredWallets(walletAndCode?.buyersWallet || []);
    } else {
      const filtered = walletAndCode?.buyersWallet?.filter((item) =>
        item?.buyersWallet?.toLowerCase().includes(searchValue)
      );
      setFilteredWallets(filtered);
    }
  };

  const handleSearchChangeAccessCodes = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInputAccessCodes(searchValue);

    if (searchValue === "") {
      setFilteredAccessCodes(walletAndCode?.accessCode || []);
    } else {
      const filtered = walletAndCode?.accessCode?.filter((item) =>
        item?.accessCode?.toLowerCase().includes(searchValue)
      );
      setFilteredAccessCodes(filtered);
    }
  };

  const handleCheckboxChangeAccessCodes = (wallet) => {
    setSelectedAccessCodes((prevSelectedWallets) =>
      prevSelectedWallets.includes(wallet)
        ? prevSelectedWallets.filter((item) => item !== wallet)
        : [...prevSelectedWallets, wallet]
    );
  };

  const handleCheckboxChangeWallet = (wallet) => {
    setSelectedWallets((prevSelectedWallets) =>
      prevSelectedWallets.includes(wallet)
        ? prevSelectedWallets.filter((item) => item !== wallet)
        : [...prevSelectedWallets, wallet]
    );
  };
  const handleCheckboxChangeRounds = (projectName, round = null) => {
    setPrivateSaleFilter((prevFilters) => {
      const existingProject = prevFilters.find(
        (item) => item.privateSale === projectName
      );

      if (existingProject) {
        // If the project exists in the filter
        if (round) {
          // If a specific round is being checked/unchecked
          const updatedRounds = existingProject.round.includes(round)
            ? existingProject.round.filter((r) => r !== round)
            : [...existingProject.round, round];

          // If no rounds are left after unchecking, remove the project entirely
          if (updatedRounds.length === 0) {
            return prevFilters.filter(
              (item) => item.privateSale !== projectName
            );
          }

          // Update the rounds for this project
          return prevFilters.map((item) =>
            item.privateSale === projectName
              ? { ...item, round: updatedRounds }
              : item
          );
        } else {
          // If no specific round is passed, remove the entire project from the filter
          return prevFilters.filter((item) => item.privateSale !== projectName);
        }
      } else {
        // If the project doesn't exist in the filter
        return [
          ...prevFilters,
          {
            privateSale: projectName,
            round: round ? [round] : [],
          },
        ];
      }
    });
  };
  const roles = [
    "god_agent",
    "mega_agent",
    "super_agent",
    "agent",
    "mini_agent",
  ];
  const getLeaderNames = () => {
    var config = {
      method: "get",
      url: `${Api_Url_Referral}/users/leaders-tree`,
      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(function (response) {
        setLeaderNames(response?.data?.data?.data);
        setLeaderNames2(response?.data?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const handleCheckboxChange = (role) => {
    setSelectedRoles((prevRoles) => {
      if (role === "all") {
        // If "All Roles" is checked, select/deselect all roles
        if (prevRoles.length === roles.length) {
          return []; // Uncheck all roles
        } else {
          return roles; // Check all roles
        }
      } else {
        // Handle individual role check/uncheck
        if (prevRoles.includes(role)) {
          return prevRoles.filter((r) => r !== role); // Remove role
        } else {
          return [...prevRoles, role]; // Add role
        }
      }
    });
  };
  const isAllRolesSelected = selectedRoles.length === roles.length;

  const getRoundsAndProjects = () => {
    var config = {
      method: "get",
      url: `${Api_Url_Referral}/project-presale/referral/rounds-filter`,
      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(function (response) {
        setRoundsData(response?.data?.data?.roundDetails);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const getWalletAndCode = () => {
    var config = {
      method: "get",
      url: `${Api_Url_Referral}/users/transaction-filters-data`,
      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data?.data;
        setWalletAndCode(resData);
        setFilteredWallets(resData?.buyersWallet);
        setFilteredAccessCodes(resData?.accessCode);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  useEffect(() => {
    getLeaderNames();
    getRoundsAndProjects();
    getWalletAndCode();
  }, []);

  useEffect(() => {
    if (usdPrice) {
      getTransactionsData();
    }
  }, [
    selectedAccessCodes?.length,
    selectedWallets?.length,
    privateSaleFilter?.length,
    selectedLeaders?.length,
    selectedRoles?.length,
    usdPrice,
    user,
    search,
    typeFilter,
    orderField,
    orderDirection,
    page,
    myTree,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const getTransactionsData = () => {
    setTransactions([]);
    setTransactionApiCalled(true);

    var config = {
      method: "post",
      url: `${Api_Url_Referral}/referral/admin/transactions`,
      data: {
        limit: 10,
        offset: page + 1,
        ...(selectedLeaders?.length > 0 && {
          nameFilter: selectedLeaders?.map((leader) => leader?.name),
        }),
        ...(selectedRoles?.length > 0 && { roleFilter: selectedRoles }),
        ...(search &&
          searchBy == "transactionHashFilter" && {
            searchFilter: search,
          }),

        ...(selectedWallets?.length > 0 && {
          walletAddressFilter: selectedWallets,
        }),
        ...(selectedAccessCodes?.length > 0 && {
          accessCodeFilter: selectedAccessCodes,
        }),
        ...(privateSaleFilter?.length > 0 && {
          privateSaleFilter: privateSaleFilter,
        }),

        orderField: orderField,
        orderDirection: orderDirection,
      },
      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(function (res) {
        console.log("trx data: ", res?.data?.data?.alltransactions);
        setTransactions(res?.data?.data?.alltransactions);
        setPageCount(res?.data?.data?.pages);
        setTransactionApiCalled(false);

        // setLoader(false);
      })
      .catch(function (error) {
        // setLoader(false);
        setTransactionApiCalled(false);
      });
  };

  const getTransactionsDataRef = (bool) => {
    setTransactions([]);
    setTransactionApiCalled(true);

    var config = {
      method: "post",
      url: `${Api_Url_Referral}/referral/admin/transactions`,
      data: {
        limit: 10,
        offset: page + 1,
        ...(selectedLeaders?.length > 0 && {
          nameFilter: selectedLeaders?.map((leader) => leader?.name),
        }),
        ...(selectedRoles?.length > 0 && { roleFilter: selectedRoles }),
        ...(search &&
          searchBy == "transactionHashFilter" && {
            searchFilter: search,
          }),

        ...(selectedWallets?.length > 0 && {
          walletAddressFilter: selectedWallets,
        }),
        ...(selectedAccessCodes?.length > 0 && {
          accessCodeFilter: selectedAccessCodes,
        }),
        ...(privateSaleFilter?.length > 0 && {
          privateSaleFilter: privateSaleFilter,
        }),
        isreferral: bool,
        orderField: orderField,
        orderDirection: orderDirection,
      },
      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(function (res) {
        console.log("trx data: ", res?.data?.data?.alltransactions);
        setTransactions(res?.data?.data?.alltransactions);
        setPageCount(res?.data?.data?.pages);
        setTransactionApiCalled(false);

        // setLoader(false);
      })
      .catch(function (error) {
        // setLoader(false);
        setTransactionApiCalled(false);
      });
  };

  useEffect(() => {
    if (user?.role == "admin") {
      GetAllRounds();
    } else {
      GetMyData();
    }
  }, [usdPrice, user]);

  const functiontogetback = () => {
    localStorage.setItem("indexvalue", 0);
    setindexwait(0);
  };

  const GetAllRounds = async () => {
    // if (account) {
    var config = {
      method: "get",
      url: `${Api_Url_Referral}/users/admin/stats?privateSale=LEIA`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        let value = response?.data?.data;
        // console.log("value: ", value);
        setTokenNftStats(value);
        if (response?.data?.data?.stats?.length > 0) {
          setadminobj(response?.data?.data?.stats[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // }
  };

  const GetMyData = async () => {
    var config = {
      method: "get",
      url: `${Api_Url_Referral}/users/my/stats?privateSale=LEIA`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        let value = response?.data?.data;
        // console.log("sdfsfsdfsdfsdfdsfsdfdf", value);
        setPDP(value?.purchasesAndDopPurchased);
        setPEU(value?.purchasedAndEarningsUsdt);
        setPEE(value?.purchasedAndEarningsEth);
        setPET(value?.purchasedAndEarningsTomi);
        setPEUsdc(value?.purchasedAndEarningsUsdc);
        setPEBTC(value?.purchasedAndEarningsWbtc);
        setPEPepe(value?.purchasedAndEarningsPepe);
        setPELink(value?.purchasedAndEarningsLink);
        setPEUni(value?.purchasedAndEarningsUni);
        setPEDop(value?.purchasedAndEarningsDopToken);

        setIND(value?.inDirectSales);
        setDT(value?.directSales);
        setPC(value?.myPurchasesChange);
        setPMC(value?.myPurchasesAmountChange);
        setLC(value?.myLeadersChange);
        setDPC(value?.myDopPurchasedChange);
        setLEC(value?.myEarningsChange);
        setgg(value?.leaders);
        setmm(value?.megas);
        setss(value?.supers);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // history.push("/login");
        }
        console.log(error);
      });
    // }
  };

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleCheckboxChangeLeaders = (leader) => {
    setSelectedLeaders(
      (prevLeaders) =>
        prevLeaders.some((l) => l.id === leader.id)
          ? prevLeaders.filter((l) => l.id !== leader.id) // Remove the leader object if already selected
          : [...prevLeaders, { id: leader.id, name: leader.name }] // Add the leader object if not selected
    );
  };

  const getAllEventKeys = (nodes, keys = []) => {
    nodes.forEach((node) => {
      keys.push(node._id.toString());
      Object.keys(node).forEach((key) => {
        if (Array.isArray(node[key]) && node[key].length > 0) {
          getAllEventKeys(node[key], keys);
        }
      });
    });
    return keys;
  };
  const areAllChildrenSelected = (node) => {
    const childNodes = getAllDescendantNodes(node);
    return childNodes.every((child) =>
      selectedLeaders.some((leader) => leader.id === child._id)
    );
  };

  const getAllDescendantNodes = (node) => {
    let descendants = [node];
    Object.keys(node).forEach((key) => {
      if (Array.isArray(node[key])) {
        node[key].forEach((child) => {
          descendants = descendants.concat(getAllDescendantNodes(child));
        });
      }
    });
    return descendants;
  };
  const handleParentCheckboxChange = (node) => {
    const allDescendants = getAllDescendantNodes(node);
    const descendantObjects = allDescendants.map((descendant) => ({
      id: descendant._id,
      name: descendant.name,
    }));

    let newSelectedLeaders;
    if (areAllChildrenSelected(node)) {
      // Deselect all descendants
      newSelectedLeaders = selectedLeaders.filter(
        (leader) => !descendantObjects.some((desc) => desc.id === leader.id)
      );
    } else {
      // Select all descendants
      newSelectedLeaders = [
        ...new Set([...selectedLeaders, ...descendantObjects]),
      ];
    }

    setSelectedLeaders(newSelectedLeaders);
  };
  const renderHierarchy = (nodes, level = 0) => {
    return nodes.map((node, index) => (
      <Accordion.Item
        key={`${node?._id}_${index}`}
        eventKey={node?._id?.toString()}
      >
        <Accordion.Header className={`accordion-header level-${level}`}>
          <div
            className="maininnerbtn"
            style={{ paddingLeft: `${level * 20}px` }}
          >
            <label className="cyberpunk-checkbox-label">
              <input
                type="checkbox"
                className="cyberpunk-checkbox"
                checked={areAllChildrenSelected(node)}
                onChange={() => handleParentCheckboxChange(node)}
              />
            </label>
            <div className="projectmain">
              <p className="projectpara">{node?.name}</p>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {Object.keys(node).map((key) => {
            if (Array.isArray(node[key]) && node[key].length > 0) {
              return (
                <Accordion key={key} defaultActiveKey="0">
                  {renderHierarchy(node[key], level + 1)}
                </Accordion>
              );
            }
            return null;
          })}
        </Accordion.Body>
      </Accordion.Item>
    ));
  };

  const allEventKeys = getAllEventKeys(leaderNames);

  function convertToTitleCase(input) {
    // console.log("cccc");
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }
  return (
    <>
      <section className="alltransaction">
        <div className="custom-container">
          {/* <button
            class="btn-back guvxgyvxvtxx"
            onClick={() => functiontogetback()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                fill="black"
              ></path>
              <path
                d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                fill="black"
              ></path>
            </svg>{" "}
            Back
          </button>
          <div className="main-heading">
            <h6>All Transactions</h6>
            {user?.role == "god_agent" && user?.specialAccess?.length > 0 ? (
              <div className="set-twice-text">
                <div className="parent-toggle-switch-custom">
                  <div class="custom-toggle">
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="checkbox"
                      checked={myTree}
                      onChange={(e) => {
                        setMyTree(e.target.checked);
                      }}
                    />
                    <label class="switch" for="checkbox">
                      <span class="slider"></span>
                    </label>
                  </div>
                </div>
                <h6>Show data from my tree only</h6>
              </div>
            ) : null}
          </div>
          {user?.role == "admin" ? (
            <div className="bottom-cards">
              <div className="single-card">
                <img
                  src="\assets\dashboard\noofpurchases.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Number of Purchases</p>
                <h6>{adminobj?.TotalPurchases}</h6>
              </div>
              <div className="single-card">
                <img
                  src="/assets/incentive-purchased.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>INCENTIV Purchased</p>
                {adminobj?.totalDopPurchased ? (
                  <h6>
                    {parseInt(
                      (
                        tokenNftStats.stats[0]?.totalDopPurchased +
                        tokenNftStats.nftStats[0]?.totalDopPurchased
                      ).toFixed(2)
                    ).toLocaleString("en-US")}{" "}
                    &nbsp;INC
                  </h6>
                ) : (
                  <h6>0.00 INC</h6>
                )}
                <div className="bottom-text">
                  <p className="uni-text">
                    <span></span>
                    {tokenNftStats
                      ? parseFloat(
                          tokenNftStats?.stats[0]?.totalDopPurchased?.toFixed(4)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    Token
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {tokenNftStats
                      ? parseInt(
                          tokenNftStats?.nftStats[0]?.totalDopPurchased?.toFixed(
                            2
                          )
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    NFT
                  </p>
                </div>
              </div>
              <div className="single-card">
                <img
                  src="\assets\dashboard\purchasedamount.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Purchased Amount</p>
                {usdPrice && adminobj ? (
                  <h6 className="datahead">
                    ~$
                    {parseInt(
                      (
                        usdPrice * adminobj?.totalPurchaseEth +
                        adminobj?.totalPurchaseUsdt +
                        dopPrice * adminobj?.totalPurchaseDopToken +
                        btcPrice * adminobj?.totalPurchaseWbtc +
                        adminobj?.totalPurchaseUsdc +
                        pepePrice * adminobj?.totalPurchasePepe +
                        linkPrice * adminobj?.totalPurchaseLink +
                        uniPrice * adminobj?.totalPurchaseUni
                      )?.toFixed(2)
                    ).toLocaleString("en-US")}{" "}
                  </h6>
                ) : (
                  <h6 className="datahead">~$0.00 USD</h6>
                )}
                <div className="bottom-text">
                  <p className="uni-text">
                    <span></span>
                    {adminobj ? adminobj?.totalPurchaseEth?.toFixed(4) : 0} ETH
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseUsdt?.toFixed(2)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    USDT
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseDopToken?.toFixed(2)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    DOP
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseWbtc?.toFixed(5)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    WBTC
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseUsdc?.toFixed(2)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    USDC
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchasePepe?.toFixed(2)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    PEPE
                  </p>{" "}
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseLink?.toFixed(2)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    LINK
                  </p>{" "}
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseUni?.toFixed(2)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    UNI
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="bottom-cards">
              <div className="single-card">
                <img
                  src="\assets\dashboard\noofpurchases.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Number of Purchases</p>
                {pdp?.length > 0 ? (
                  <h6>
                    {pdp[0]?.TotalPurchases > 0 ? pdp[0]?.TotalPurchases : 0}{" "}
                    <span className={pc > 0 ? "green-text" : "red-text"}>
                      {pc ? pc.toFixed(1) + "% " : ""}
                    </span>
                  </h6>
                ) : (
                  <h6>0</h6>
                )}
              </div>
              <div className="single-card">
                <img
                  src="/assets/incentive-purchased.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>INCENTIV Purchased</p>
                {usdPrice && pdp ? (
                  <h6>
                    {pdp[0]?.totalDopPurchased > 0
                      ? parseFloat(pdp[0]?.totalDopPurchased)
                          .toFixed(2)
                          ?.toLocaleString("en-US")
                      : 0}
                    &nbsp;INC{" "}
                    <span className={dpc > 0 ? "green-text" : "red-text"}>
                      {dpc ? dpc.toFixed(1) + "% " : ""}
                    </span>
                  </h6>
                ) : (
                  <h6>0.00 INC</h6>
                )}
                <div className="bottom-text">
                  <p className="uni-text">
                    <span></span>
                    {dt[0]?.totalDirectDop
                      ? dt[0]?.totalDirectDop?.toFixed(4)
                      : 0}
                  </p>
                  <p className="uni-text">
                    <span></span>~
                    {ind[0]?.totalInDirectDop
                      ? ind[0]?.totalInDirectDop?.toFixed(4)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="single-card">
                <img
                  src="\assets\dashboard\purchasedamount.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Purchased Amount</p>
                {(usdPrice && pee) || peu || (tomiPrice && pet) ? (
                  <h6 className="datahead">
                    {pee?.length > 0
                      ? "~$" +
                        parseFloat(
                          (
                            usdPrice *
                              (pee[0]?.totalPurchaseEth
                                ? pee[0]?.totalPurchaseEth
                                : 0) +
                            (peu[0]?.totalPurchaseUsdt
                              ? peu[0]?.totalPurchaseUsdt
                              : 0) +
                            tomiPrice *
                              (pet[0]?.totalPurchaseTomi
                                ? pet[0]?.totalPurchaseTomi
                                : 0) +
                            btcPrice *
                              (peBtc[0]?.totalPurchaseWbtc
                                ? peBtc[0]?.totalPurchaseWbtc
                                : 0) +
                            pepePrice *
                              (pePepe[0]?.totalPurchasePepe
                                ? pePepe[0]?.totalPurchasePepe
                                : 0) +
                            linkPrice *
                              (peLink[0]?.totalPurchaseLink
                                ? peLink[0]?.totalPurchaseLink
                                : 0) +
                            uniPrice *
                              (peUni[0]?.totalPurchaseUni
                                ? peUni[0]?.totalPurchaseUni
                                : 0) +
                            dopPrice *
                              (peUni[0]?.totalPurchaseDopToken
                                ? peUni[0]?.totalPurchaseDopToken
                                : 0)
                          )?.toFixed(4)
                        ).toLocaleString("en-US")
                      : 0}{" "}
                    <span className={pmc > 0 ? "green-text" : "red-text"}>
                      {pmc ? pmc.toFixed(1) + "% " : ""}
                    </span>
                  </h6>
                ) : (
                  <h6 className="datahead">~$0.00 USD</h6>
                )}
                {pee?.length > 0 ? (
                  <div className="bottom-text">
                    <p className="uni-text">
                      <span></span>
                      {pee[0]?.totalPurchaseEth
                        ? pee[0]?.totalPurchaseEth?.toFixed(4)
                        : 0}{" "}
                      ETH
                    </p>
                    <p className="uni-text">
                      <span></span>
                      {peu[0]?.totalPurchaseUsdt
                        ? parseFloat(
                            peu[0]?.totalPurchaseUsdt?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      USDT
                    </p>
                    <p className="uni-text">
                      <span></span>
                      {pet[0]?.totalPurchaseDopToken
                        ? parseFloat(
                            pet[0]?.totalPurchaseDopToken?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      DOP
                    </p>
                    <p className="uni-text">
                      <span></span>
                      {peBtc[0]?.totalPurchaseWbtc
                        ? parseFloat(
                            peBtc[0]?.totalPurchaseWbtc?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      WBTC
                    </p>
                    <p className="uni-text">
                      <span></span>
                      {peUsdc[0]?.totalPurchaseUsdc
                        ? parseFloat(
                            peUsdc[0]?.totalPurchaseUsdc?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      USDC
                    </p>
                    <p className="uni-text">
                      <span></span>
                      {pePepe[0]?.totalPurchasePepe
                        ? parseFloat(
                            pePepe[0]?.totalPurchasePepe?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      PEPE
                    </p>{" "}
                    <p className="uni-text">
                      <span></span>
                      {peLink[0]?.totalPurchaseLink
                        ? parseFloat(
                            peLink[0]?.totalPurchaseLink?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      LINK
                    </p>{" "}
                    <p className="uni-text">
                      <span></span>
                      {peUni[0]?.totalPurchaseUni
                        ? parseFloat(
                            pet[0]?.totalPurchaseUni?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      UNI
                    </p>
                  </div>
                ) : (
                  <div className="bottom-text">
                    <p className="uni-text">
                      <span></span>0 ETH
                    </p>
                    <p className="uni-text">
                      <span></span>0 USDT
                    </p>
                    <p className="uni-text">
                      <span></span>0 DOP
                    </p>
                    <p className="uni-text">
                      <span></span>0 WBTC
                    </p>
                    <p className="uni-text">
                      <span></span>0 USDC
                    </p>
                    <p className="uni-text">
                      <span></span>0 PEPE
                    </p>{" "}
                    <p className="uni-text">
                      <span></span>0 LINK
                    </p>{" "}
                    <p className="uni-text">
                      <span></span>0 UNI
                    </p>
                  </div>
                )}
              </div>
            </div>
          )} */}
          <div className="main-heading">
            <div>
              {/* <p className="selfhead">Premium Projects</p> */}
              <p className="selfhead">self serve Projects</p>
              {user?.accessCode ? (
                <h6>All Transactions</h6>
              ) : (
                <h6>All Transactions</h6>
              )}
            </div>

            <div className="multi-items">
              {user?.role == "god_agent" && user?.specialAccess?.length > 0 ? (
                <div className="set-twice-text">
                  <div className="parent-toggle-switch-custom">
                    <div class="custom-toggle">
                      <input
                        type="checkbox"
                        class="checkbox"
                        id="checkbox"
                        checked={myTree}
                        onChange={(e) => {
                          setMyTree(e.target.checked);
                        }}
                      />
                      <label class="switch" for="checkbox">
                        <span class="slider"></span>
                      </label>
                    </div>
                  </div>
                  <h6>Show data from my tree only</h6>
                </div>
              ) : null}

              <div className="rightside-content">
                <div className="custom-option-field">
                  <Dropdown className="positionset">
                    <Dropdown.Toggle id="dropdown-basic">
                      Hash
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="7"
                        viewBox="0 0 13 7"
                        fill="none"
                      >
                        <path
                          d="M0 0.5L2.22397e-07 3.0462L6.2 6.5L6.2 3.9538L0 0.5Z"
                          fill="#02171D"
                        />
                        <path
                          d="M12.3984 0.5L12.3984 3.0462L6.19844 6.5L6.19844 3.9538L12.3984 0.5Z"
                          fill="#02171D"
                        />
                      </svg>
                    </Dropdown.Toggle>
                  </Dropdown>
                  <input
                    style={{ paddingLeft: "90px" }}
                    type="search"
                    placeholder="Search..."
                    onChange={(e) => {
                      if (page != 0) {
                        setPage(0);
                      }
                      setSearch(e.target.value);
                    }}
                  />
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="img-fluid search-icon"
                  >
                    <path
                      d="M7.66671 14.5C3.90004 14.5 0.833374 11.4334 0.833374 7.66671C0.833374 3.90004 3.90004 0.833374 7.66671 0.833374C11.4334 0.833374 14.5 3.90004 14.5 7.66671C14.5 11.4334 11.4334 14.5 7.66671 14.5ZM7.66671 1.83337C4.44671 1.83337 1.83337 4.45337 1.83337 7.66671C1.83337 10.88 4.44671 13.5 7.66671 13.5C10.8867 13.5 13.5 10.88 13.5 7.66671C13.5 4.45337 10.8867 1.83337 7.66671 1.83337Z"
                      fill="black"
                    />
                    <path
                      d="M14.6666 15.1666C14.54 15.1666 14.4133 15.12 14.3133 15.02L12.98 13.6866C12.7866 13.4933 12.7866 13.1733 12.98 12.98C13.1733 12.7866 13.4933 12.7866 13.6866 12.98L15.02 14.3133C15.2133 14.5066 15.2133 14.8266 15.02 15.02C14.92 15.12 14.7933 15.1666 14.6666 15.1666Z"
                      fill="black"
                    />
                  </svg>
                </div>

                <button className="whitnewbtn" onClick={handleShow3}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.29339 15.1C6.97339 15.1 6.66006 15.02 6.36672 14.86C5.78006 14.5334 5.42672 13.94 5.42672 13.2734V9.74002C5.42672 9.40669 5.20672 8.90669 5.00006 8.65336L2.50672 6.01336C2.08672 5.59336 1.76672 4.87336 1.76672 4.33336V2.80002C1.76672 1.73336 2.57339 0.900024 3.60006 0.900024H12.4001C13.4134 0.900024 14.2334 1.72002 14.2334 2.73336V4.20002C14.2334 4.90002 13.8134 5.69336 13.4201 6.08669L10.5334 8.64002C10.2534 8.87336 10.0334 9.38669 10.0334 9.80002V12.6667C10.0334 13.26 9.66006 13.9467 9.19339 14.2267L8.27339 14.82C7.97339 15.0067 7.63339 15.1 7.29339 15.1ZM3.60006 1.90002C3.13339 1.90002 2.76672 2.29336 2.76672 2.80002V4.33336C2.76672 4.58002 2.96672 5.06002 3.22006 5.31336L5.76006 7.98669C6.10006 8.40669 6.43339 9.10669 6.43339 9.73336V13.2667C6.43339 13.7 6.73339 13.9134 6.86006 13.98C7.14006 14.1334 7.48006 14.1334 7.74006 13.9734L8.66672 13.38C8.85339 13.2667 9.04006 12.9067 9.04006 12.6667V9.80002C9.04006 9.08669 9.38672 8.30002 9.88672 7.88002L12.7401 5.35336C12.9667 5.12669 13.2401 4.58669 13.2401 4.19336V2.73336C13.2401 2.27336 12.8667 1.90002 12.4067 1.90002H3.60006Z"
                      fill="black"
                    />
                    <path
                      d="M3.99995 7.16664C3.90661 7.16664 3.81995 7.13997 3.73328 7.09331C3.49995 6.94664 3.42661 6.63331 3.57328 6.39997L6.85995 1.13331C7.00661 0.899972 7.31328 0.826639 7.54661 0.973306C7.77995 1.11997 7.85328 1.42664 7.70661 1.65997L4.41995 6.92664C4.32661 7.07997 4.16661 7.16664 3.99995 7.16664Z"
                      fill="black"
                    />
                  </svg>
                  filter
                </button>
              </div>
            </div>
          </div>

          <DataTable
            page={page}
            handlePageClick={handlePageChange}
            setSearch={setSearch}
            search={search}
            usdPrice={usdPrice}
            transactions={transactions}
            transactionApiCalled={transactionApiCalled}
            // setTransactions={setTransactions}
            pageCount={pageCount}
            user={user}
            setOrderDirection={setOrderDirection}
            setOrderField={setOrderField}
            tok={tok}
            selectedLeaders={selectedLeaders}
            selectedRoles={selectedRoles}
            searchBy={searchBy}
            selectedAccessCodes={selectedAccessCodes}
            selectedWallets={selectedWallets}
            privateSaleFilter={privateSaleFilter}
            orderDirection={orderDirection}
            orderField={orderField}
          />

          <div className="footer-content mt-2">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                          >
                            <path
                              d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z"
                              fill="#329879"
                            />
                            <path
                              d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z"
                              fill="#329879"
                            />
                            <path
                              d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                          >
                            <path
                              d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z"
                              fill="#329879"
                            />
                            <path
                              d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z"
                              fill="#329879"
                            />
                            <path
                              d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z"
                              fill="#329879"
                            />
                          </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page}
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <Offcanvas
        show={show3}
        onHide={handleClose3}
        placement="end"
        className="leadersfilter"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Projects & Rounds
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M12.9182 0.178772H6.68816H1.07816C0.118161 0.178772 -0.361838 1.33877 0.318162 2.01877L5.49816 7.19877C6.32816 8.02877 7.67816 8.02877 8.50816 7.19877L10.4782 5.22877L13.6882 2.01877C14.3582 1.33877 13.8782 0.178772 12.9182 0.178772Z"
                  fill="black"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Accordion defaultActiveKey="0">
                {roundsData.map((project, projectIndex) => (
                  <Accordion.Item
                    key={projectIndex}
                    eventKey={projectIndex.toString()}
                  >
                    <Accordion.Header>
                      <div className="maininnerbtn">
                        <label className="cyberpunk-checkbox-label">
                          <input
                            type="checkbox"
                            className="cyberpunk-checkbox"
                            checked={privateSaleFilter.some(
                              (item) =>
                                item.privateSale === project.privateSale &&
                                item.round.length === project.totalRounds
                            )}
                            onChange={() => {
                              // Check/uncheck all rounds
                              const allRoundsSelected = privateSaleFilter.some(
                                (item) =>
                                  item.privateSale === project.privateSale &&
                                  item.round.length === project.totalRounds
                              );
                              if (allRoundsSelected) {
                                project.rounds.forEach((round) => {
                                  handleCheckboxChangeRounds(
                                    project.privateSale,
                                    round
                                  );
                                });
                              } else {
                                for (let i = 1; i <= project.totalRounds; i++) {
                                  handleCheckboxChangeRounds(
                                    project.privateSale,
                                    i
                                  );
                                }
                              }
                            }}
                          />
                        </label>
                        <div className="projectmain">
                          <div className="projectmainimg">
                            <img
                              // src={`/assets/${project.privateSale.toLowerCase()}.svg`}
                              src={project?.projectImg}
                              alt="projectinnerimg"
                              className="projectinnerimg"
                            />
                          </div>
                          <p className="projectpara">{project.privateSale}</p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {Array.from(
                        { length: project.totalRounds },
                        (_, roundIndex) => (
                          <div key={roundIndex} className="inneracc">
                            <label className="cyberpunk-checkbox-label">
                              <input
                                type="checkbox"
                                className="cyberpunk-checkbox"
                                checked={privateSaleFilter.some(
                                  (item) =>
                                    item.privateSale === project.privateSale &&
                                    item.round.includes(roundIndex + 1)
                                )}
                                onChange={() =>
                                  handleCheckboxChangeRounds(
                                    project.privateSale,
                                    roundIndex + 1
                                  )
                                }
                              />
                            </label>
                            <p className="inneracccpara">
                              Round {roundIndex + 1}
                            </p>
                          </div>
                        )
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Dropdown.Menu>
          </Dropdown>
          <div className="spanmain">
            {privateSaleFilter.map((project) =>
              project.round.map((round, index) => {
                return (
                  <div
                    className="innerspan"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCheckboxChangeRounds(project.privateSale, round);
                    }}
                  >
                    {project.privateSale} Round {round}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M5.14282 12.8571L12.8571 5.14282"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8571 12.8571L5.14282 5.14282"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                );
              })
            )}
          </div>

          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Sale Type
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
              >
                <path
                  d="M12.9182 0.178772H6.68816H1.07816C0.118161 0.178772 -0.361838 1.33877 0.318162 2.01877L5.49816 7.19877C6.32816 8.02877 7.67816 8.02877 8.50816 7.19877L10.4782 5.22877L13.6882 2.01877C14.3582 1.33877 13.8782 0.178772 12.9182 0.178772Z"
                  fill="black"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <div className="searchinput">
                <input
                  type="text"
                  placeholder="Search"
                  className="innerinput"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="searchimg"
                >
                  <path
                    d="M7.66671 14.5002C3.90004 14.5002 0.833374 11.4335 0.833374 7.66683C0.833374 3.90016 3.90004 0.833496 7.66671 0.833496C11.4334 0.833496 14.5 3.90016 14.5 7.66683C14.5 11.4335 11.4334 14.5002 7.66671 14.5002ZM7.66671 1.8335C4.44671 1.8335 1.83337 4.4535 1.83337 7.66683C1.83337 10.8802 4.44671 13.5002 7.66671 13.5002C10.8867 13.5002 13.5 10.8802 13.5 7.66683C13.5 4.4535 10.8867 1.8335 7.66671 1.8335Z"
                    fill="#929292"
                  />
                  <path
                    d="M14.6666 15.1666C14.54 15.1666 14.4133 15.12 14.3133 15.02L12.98 13.6866C12.7866 13.4933 12.7866 13.1733 12.98 12.98C13.1733 12.7866 13.4933 12.7866 13.6866 12.98L15.02 14.3133C15.2133 14.5066 15.2133 14.8266 15.02 15.02C14.92 15.12 14.7933 15.1666 14.6666 15.1666Z"
                    fill="#929292"
                  />
                </svg>
              </div> */}
              <div onClick={() => getTransactionsData()} className="inneritem">
                <p className="inneracccpara">All</p>
              </div>
              <div
                onClick={() => getTransactionsDataRef(true)}
                className="inneritem"
              >
                <p className="inneracccpara">Referrals</p>
              </div>
              <div
                onClick={() => getTransactionsDataRef(false)}
                className="inneritem"
              >
                <p className="inneracccpara">Non Referrals</p>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <div className="spanmain">
            {selectedRoles?.length > 0
              ? selectedRoles?.map((item) => {
                  return (
                    <div
                      className="innerspan"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleCheckboxChange(item);
                      }}
                    >
                      {convertToTitleCase(item)}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M5.14282 12.8571L12.8571 5.14282"
                          stroke="white"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.8571 12.8571L5.14282 5.14282"
                          stroke="white"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  );
                })
              : null}
          </div>

          <div className="bottombtns">
            <button
              className="whitebtn"
              onClick={() => {
                // setSearch("");
                // setRoundFilter([]);
                // setLeaderNameFilter("");
                // setSaleTypeFilter("");
                setPrivateSaleFilter([]);
                setSelectedLeaders([]);
                setSelectedRoles([]);
                setSelectedAccessCodes([]);
                setSelectedWallets([]);
              }}
            >
              Clear all
            </button>
            <button
              className="greybtn"
              onClick={() => {
                getTransactionsData();
                handleClose3();
              }}
            >
              Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AllTransaction;
