import React, { useEffect, useState } from "react";
import DataTable from "./DataTable/DataTable";
import "./tokensettings.scss";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import Web3 from "web3";
import { toast } from "react-toastify";
import Loader from "../../../hooks/loader";
import useWeb3 from "../../../hooks/useWeb3";
import { getERC20Token } from "../../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
// import { getdoproundcreatecontract } from "../../../utils/contractHelpers";
import { getPreSaleContract } from "../../../utils/contractHelpers";
import {
  getinstitutionpresalecontract,
  getLEIAInstitutionPreSaleContract,
  getOlympusInstitutionPreSaleContract,
} from "../../../utils/contractHelpers";
import { getLEIAPreSaleContract } from "../../../utils/contractHelpers";
import {
  getOlympusPreSaleContract,
  getNFAPreSaleContract,
  getAstroNovaPreSaleContract,
} from "../../../utils/contractHelpers";

import Environment from "../../../utils/Environment";

const Tokensettings = () => {
  const val = localStorage.getItem("accessToken");
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const [project, setProject] = useState("ASTRANOVA");
  const [selectedItem, setSelectedItem] = useState({
    imgSrc: "/assets/astronova.svg",
    text: "ASTRANOVA",
  });

  const [loader, setLoader] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setTokenDecimals("");
    setTokenName("");
    setTokenPriceFeed("");
    setNormalisationFactorNFT("");
    setNormalisationFactorToken("");
    setTokenSymbol("");
    setContractAddress("");
  };
  const handleShow = () => {
    setShow(true);
  };

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [tokenData, setTokenData] = useState([]);
  const [search, setSearch] = useState("");

  const [orderField, setOrderField] = useState("");
  const [orderDirection, setOrderDirection] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [contractAddress, setContractAddress] = useState([]);
  const [tokenName, setTokenName] = useState("");

  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenDecimals, setTokenDecimals] = useState("");
  const [slippageTolerance, setSlippageTolerance] = useState("");
  const [normalisationFactorNFT, setNormalisationFactorNFT] = useState("");
  const [normalisationFactorToken, setNormalisationFactorToken] = useState("");
  const [tokenPriceFeed, setTokenPriceFeed] = useState("");
  const [tolerance, setTolerance] = useState("");

  const [errors, setErrors] = useState({});

  const handleItemClick = (imgSrc, text) => {
    setSelectedItem({ imgSrc, text });
    setProject(text);
  };

  const handleContractChange = async (e) => {
    try {
      let address = [e.target.value];
      setContractAddress(address);
      const contract = getERC20Token(e.target.value, web3);
      const name = await contract.methods.name().call();
      const symbol = await contract.methods.symbol().call();
      const decimals = await contract.methods.decimals().call();
      setTokenName(name);
      setTokenSymbol(symbol);
      setTokenDecimals(decimals);
    } catch (error) {
      throw error;
    }

    // await axios
    //   .get("https://api.coingecko.com/api/v3/coins/list")
    //   .then((response) => {
    //     // Data contains an array of tokens with their details
    //     console.log(response?.data);
    //     // Access each token's name, symbol, and icon using data[i].name, data[i].symbol, data[i].image
    //   })
    //   .catch((error) => console.error("Error fetching data:", error));
  };

  const connectwalletfirst = () => {
    toast.error("Please connect your wallet first ", {
      position: "top-right",
      autoClose: 3000,
    });
  };

  useEffect(() => {
    getTokenData();
  }, [search, orderField, orderDirection, statusFilter, project]);

  const getTokenData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/tokens?limit=25&offset=1&privateSale=${project}`,

      headers: {
        Authorization: "Bearer " + val,
      },
    };
    let dynamicQuery = config.url;

    if (search) {
      dynamicQuery += `&search=${search}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }
    if (statusFilter == "active") {
      dynamicQuery += `&status=${true}`;
    } else if (statusFilter == "inactive") {
      dynamicQuery += `&status=${false}`;
    }
    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        setTokenData(res?.data?.data?.tokens);

        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const toggleStatus = async (id) => {
    var config = {
      method: "patch",
      url: `${Api_Url}/tokens/${id}/toggle-status`,

      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then(function (res) {
        getTokenData();
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const addToken = async () => {
    if (account) {
      if (ValidateForm()) {
        const data = {
          ...(tokenName && { name: tokenName }),
          address: contractAddress[0],
          symbol: tokenSymbol,
          decimal: tokenDecimals,
          privateSale: project,
        };
        var config = {
          method: "post",
          url: `${Api_Url}/tokens`,
          data: data,
          headers: {
            Authorization: "Bearer " + val,
          },
        };
        setLoader(true);
        await axios(config)
          .then((res) => {
            const resData = res?.data?.data;
            setPriceFeed();
            getTokenData();
          })
          .catch((err) => {
            console.log("err response: ", err?.response);
            if (err?.response?.status == 409) {
              setPriceFeed();
              handleClose();
              handleShow1();
              // setLoader(false);
              // toast.error(err?.response?.message);
            } else {
              setLoader(false);
            }
            toast.error(err?.response?.message);
          });
      }
    } else {
      connectwalletfirst();
    }
  };

  const setPriceFeed = async () => {
    let contract, contractInstitution;
    if (project == "ASTRANOVA") {
      contract = getAstroNovaPreSaleContract(
        Environment.AstroNovaPreSale,
        web3
      );
      // contractInstitution = getNFAInstitutionPreSaleContract(
      //   Environment.NFAInstitutionPreSale,
      //   web3
      // );
    } else if (project == "NFA") {
      contract = getNFAPreSaleContract(Environment.NFAPreSale, web3);
      // contractInstitution = getNFAInstitutionPreSaleContract(
      //   Environment.NFAInstitutionPreSale,
      //   web3
      // );
    } else if (project == "LEIA") {
      contract = getLEIAPreSaleContract(Environment.LEIAPreSale, web3);
      contractInstitution = getLEIAInstitutionPreSaleContract(
        Environment.LEIAInstitutionPreSale,
        web3
      );
    } else if (project == "OLYMPUS") {
      contract = getOlympusPreSaleContract(Environment.OlympusPreSale, web3);
      contractInstitution = getOlympusInstitutionPreSaleContract(
        Environment.OlympusInstitutionPreSale,
        web3
      );
    } else if (project == "INCENTIV") {
      contract = getPreSaleContract(Environment.PreSale, web3);
      contractInstitution = getinstitutionpresalecontract(
        Environment.InstitutionPreSale,
        web3
      );
    }

    try {
      let data = [
        [
          tokenPriceFeed,
          parseInt(normalisationFactorToken),
          parseInt(normalisationFactorNFT),
          parseInt(tolerance),
        ],
      ];
      let dataInstitution = [
        [
          tokenPriceFeed,
          parseInt(normalisationFactorToken),
          parseInt(tolerance),
        ],
      ];
      const createround1 = await contract.methods
        .setTokenPriceFeed(contractAddress, data)
        .send({ from: account })
        .on("transactionHash", (tx) => {
          return tx.transactionHash;
        });

      if (createround1) {
        console.log(contractAddress, dataInstitution, "data institution");

        const createroundInstitution = await contractInstitution.methods
          .setTokenPriceFeed(contractAddress, dataInstitution)
          .send({ from: account })
          .on("transactionHash", (tx) => {
            return tx.transactionHash;
          });
        if (createroundInstitution) {
          handleClose();
          handleShow1();
          setLoader(false);
        }
      }
    } catch (e) {
      handleClose();
      handleShow1();
      setLoader(false);
      console.log(e);
    }
  };

  const ValidateForm = () => {
    // let input = userInput;
    let errors = {};
    let isValid = true;

    if (!contractAddress) {
      isValid = false;
      errors["address"] = "*Please enter a Contract Address";
    }

    if (!tokenSymbol) {
      isValid = false;
      errors["symbol"] = "*Please enter a Symbol";
    }

    if (!tokenDecimals) {
      isValid = false;
      errors["decimals"] = "*Please enter a Token Decimals";
    }

    // if (!slippageTolerance) {
    //   isValid = false;
    //   errors["tolerance"] = "*Please enter a Slippage Tolerance";
    // }

    if (
      (tokenSymbol == "eth" ||
        tokenSymbol == "ETH" ||
        contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
      !normalisationFactorToken
    ) {
      isValid = false;
      errors["NFToken"] = "*Please enter a Normalisation Factor for token";
    }
    if (
      (tokenSymbol == "eth" ||
        tokenSymbol == "ETH" ||
        contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
      !normalisationFactorNFT
    ) {
      isValid = false;
      errors["NFNFT"] = "*Please enter a Normalisation Factor for NFT";
    }

    if (
      (tokenSymbol == "eth" ||
        tokenSymbol == "ETH" ||
        contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
      !tolerance
    ) {
      isValid = false;
      errors["tolerance"] = "*Please enter a tolerance";
    }

    if (
      (tokenSymbol == "eth" ||
        tokenSymbol == "ETH" ||
        contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") &&
      !tokenPriceFeed
    ) {
      isValid = false;
      errors["priceFeed"] = "*Please enter a Normalisation Factor for NFT";
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <>
      {loader && <Loader />}
      <section className="salesround">
        <div className="custom-container">
          <div className="main-heading">
            <h6>Token Settings</h6>
            <div className="parent-btns tokensettingsbtns">
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="projectmain">
                    {selectedItem.imgSrc && (
                      <img
                        src={selectedItem.imgSrc}
                        alt="projectimg"
                        className="projectimg"
                        width={24}
                      />
                    )}
                    <p className="projectpara">{selectedItem.text}</p>
                  </div>
                  <img
                    src="/assets/arrow-down.svg"
                    alt="arrow-down"
                    className="img-fluid"
                  />
                </button>
                <ul className="dropdown-menu">
                  {/* <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleItemClick("", "All Projects")}
                    >
                      All Projects
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleItemClick("/assets/astronova.svg", "ASTRANOVA")
                      }
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/astronova.svg"
                          alt="projectimg"
                          className="projectimg"
                          width={24}
                        />
                        <p className="projectpara">Astra Nova</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleItemClick("/assets/nfa.svg", "NFA")}
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/nfa.svg"
                          alt="projectimg"
                          className="projectimg"
                          width={24}
                        />
                        <p className="projectpara">NFA</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleItemClick("/assets/leia.svg", "LEIA")
                      }
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/leia.svg"
                          alt="projectimg"
                          className="projectimg"
                          width={24}
                        />
                        <p className="projectpara">Leia</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleItemClick("/assets/olympus.svg", "OLYMPUS")
                      }
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/olympus.svg"
                          alt="projectimg"
                          className="projectimg"
                          width={24}
                        />
                        <p className="projectpara">Olypmus</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleItemClick(
                          "/assets/incentiv-new-logo.svg",
                          "INCENTIV"
                        )
                      }
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/incentiv-new-logo.svg"
                          alt="projectimg"
                          className="projectimg"
                        />
                        <p className="projectpara">Incentiv</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleItemClick("/assets/dop.svg", "DOP")}
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/dop.svg"
                          alt="projectimg"
                          className="projectimg"
                        />
                        <p className="projectpara">DOP</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleItemClick("/assets/gems.svg", "GEMS")
                      }
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/gems.svg"
                          alt="projectimg"
                          className="projectimg"
                        />
                        <p className="projectpara">Gems</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="custom-option-field">
                <input
                  type="search"
                  placeholder="Search..."
                  className="inputsizee"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <img
                  src="\assets\search-icon.svg"
                  alt="img"
                  className="img-fluid search-icon"
                />
              </div>
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle dsfdsf"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {statusFilter == "active"
                    ? "Active"
                    : statusFilter == "inactive"
                    ? "Inactive"
                    : "All"}
                  <img
                    src="\assets\arrow-down.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={() => setStatusFilter("")}
                    >
                      All
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={() => setStatusFilter("active")}
                    >
                      Active
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={() => setStatusFilter("inactive")}
                    >
                      Inactive
                    </a>
                  </li>
                </ul>
              </div>
              <button className="zdgvzfxv" onClick={handleShow}>
                Add Token
              </button>
            </div>
          </div>
          <DataTable
            tokenData={tokenData}
            toggleStatus={toggleStatus}
            setOrderDirection={setOrderDirection}
            setOrderField={setOrderField}
          />
        </div>
      </section>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  placeholder="Enter token contract address"
                  value={contractAddress}
                  onChange={(e) => {
                    handleContractChange(e);
                  }}
                />
                {/* <label>Contract Address</label> */}
              </div>
            </div>
            <div style={{ position: "unset" }} className="text-danger mb-4">
              <small>{errors.address}</small>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  placeholder="Enter Token Symbol"
                  value={tokenSymbol}
                  onChange={(e) => {
                    setTokenSymbol(e.target.value);
                  }}
                />
                {/* <label>Token Symbol</label> */}
              </div>
            </div>
            <div style={{ position: "unset" }} className="text-danger mb-4">
              <small>{errors.symbol}</small>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  placeholder="Enter Token Decimals"
                  value={tokenDecimals}
                  onChange={(e) => {
                    setTokenDecimals(e.target.value);
                  }}
                />
                {/* <label>Token Decimals</label> */}
              </div>
            </div>
            <div style={{ position: "unset" }} className="text-danger mb-4">
              <small>{errors.decimals}</small>
            </div>
            {tokenSymbol == "eth" ||
            tokenSymbol == "ETH" ||
            contractAddress == "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE" ||
            contractAddress == "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599" ||
            contractAddress == "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" ||
            contractAddress == "0xdAC17F958D2ee523a2206206994597C13D831ec7" ||
            contractAddress == "0x514910771AF9Ca656af840dff83E8264EcF986CA" ||
            contractAddress == "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984" ||
            tokenSymbol == "usdc" ||
            tokenSymbol == "USDC" ||
            tokenSymbol == "USDT" ||
            tokenSymbol == "usdt" ||
            tokenSymbol == "wbtc" ||
            tokenSymbol == "WBTC" ||
            tokenSymbol == "LINK" ||
            tokenSymbol == "link" ||
            tokenSymbol == "UNI" ||
            tokenSymbol == "uni" ? (
              <>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      placeholder="Normalisation Factor TOKEN"
                      value={normalisationFactorToken}
                      onChange={(e) => {
                        const input = e.target.value;
                        const isValidInput =
                          /^([0-9]+)?(\.[0-9]*)?$/.test(input) &&
                          parseFloat(input) >= 0;

                        if (isValidInput || input === "") {
                          setNormalisationFactorToken(input);
                        }
                      }}
                    />
                    <label>Normalisation Factor TOKEN</label>
                  </div>
                </div>
                <div style={{ position: "unset" }} className="text-danger mb-4">
                  <small>{errors.NFToken}</small>
                </div>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      placeholder="Normalisation Factor NFT"
                      value={normalisationFactorNFT}
                      onChange={(e) => {
                        const input = e.target.value;
                        const isValidInput =
                          /^([0-9]+)?(\.[0-9]*)?$/.test(input) &&
                          parseFloat(input) >= 0;

                        if (isValidInput || input === "") {
                          setNormalisationFactorNFT(input);
                        }
                      }}
                    />
                    <label>Normalisation Factor NFT</label>
                  </div>
                </div>
                <div style={{ position: "unset" }} className="text-danger mb-4">
                  <small>{errors.NFNFT}</small>
                </div>

                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      placeholder={`Enter Tolerance`}
                      value={tolerance}
                      onChange={(e) => {
                        const input = e.target.value;
                        setTolerance(input);
                      }}
                    />
                    <label>Tolerance</label>
                  </div>
                </div>
                <div style={{ position: "unset" }} className="text-danger mb-4">
                  <small>{errors.tolerance}</small>
                </div>

                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      placeholder={`Enter Price Feed Address`}
                      value={tokenPriceFeed}
                      onChange={(e) => {
                        const input = e.target.value;
                        setTokenPriceFeed(input);
                      }}
                    />
                    <label>Price Feed Address</label>
                  </div>
                </div>
                <div style={{ position: "unset" }} className="text-danger mb-4">
                  <small>{errors.priceFeed}</small>
                </div>
              </>
            ) : null}
          </div>
          <div className="twice-btn">
            <button onClick={handleClose} className="btn-cancel">
              Back
            </button>
            <button
              className="btn-add"
              onClick={() => {
                addToken();
              }}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="connectwallet-modal buy-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="/assets/happy.svg" alt="img" className="img-fluid" />
            <p>Token added successfully</p>
            <button onClick={handleClose1} className="btn-common">
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Tokensettings;
