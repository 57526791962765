import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPaginate from "react-paginate";

const DataTable = ({
  setRoutes,
  setShow,
  agents,
  setAgents,
  pages,
  handlePageChange,
  page,
  pageCount,
  currentPage,
  GetAllRounds,
  setUserDel,
  sub,
  setOrderDirection,
  setOrderField,
  setBlockUnblock,
  blockUnblock,
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
  pagination,
}) => {
  // const [usdPrice, setUsdPrice] = useState(0);
  const [rend, setRend] = useState(false);

  // useEffect(() => {
  //   axios
  //     .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
  //     .then((res) => {
  //       setUsdPrice(res?.data?.USD);
  //       // setEthUsd(res.data);
  //     });
  // }, []);

  const [copy, setCopy] = useState(false);
  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  function convertDateFormat(input) {
    const date = new Date(input);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${day}/${month}/${year}\n${hours}:${minutes} UTC`;
  }

  const EarningsSort = (val) => {
    let data = agents;
    if (val === "asc") {
      data.sort(
        (a, b) =>
          b?.myEarningsEth * usdPrice +
          b?.myEarningsUsdt -
          (a?.myEarningsEth * usdPrice + a?.myEarningsUsdt)
      );
    } else {
      data.sort(
        (a, b) =>
          a?.myEarningsEth * usdPrice +
          a?.myEarningsUsdt -
          (b?.myEarningsEth * usdPrice + b?.myEarningsUsdt)
      );
    }
    setAgents(data);
    setRend(!rend);
  };

  const PurchasesSort = (val) => {
    let data = agents;
    if (val === "asc") {
      data.sort(
        (a, b) =>
          b?.totalEth * usdPrice +
          b?.totalUsdt -
          (a?.totalEth * usdPrice + a?.totalUsdt)
      );
    } else {
      data.sort(
        (a, b) =>
          a?.totalEth * usdPrice +
          a?.totalUsdt -
          (b?.totalEth * usdPrice + b?.totalUsdt)
      );
    }
    setAgents(data);
    setRend(!rend);
  };

  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date/Time{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("createdAt");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Name/Wallet{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("name");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Role{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                {/* <th>
                  <div className="parent-tag">
                    Wallet Address{" "}
                    <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div>
                  </div>
                </th> */}
                <th>
                  <div className="parent-tag">
                    Tokens
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("totalDopDirect");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Leader Earning{" "}
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Total Purchases{" "}
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                {sub != true ? <th>Action</th> : null}
              </tr>
            </thead>
            <tbody>
              {agents?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="twice-text">
                        <h6>
                          {convertDateFormat(item?.createdAt)?.slice(0, 10)}
                        </h6>
                        <p>
                          {convertDateFormat(item?.createdAt)?.slice(12, 20)}
                        </p>
                      </div>
                    </td>
                    <td>
                      {item?.name}
                      <div className="walletaddresspara">
                        <p>
                          {copy ? (
                            <span className="adadasdasdasdasdasdsad">
                              {item?.walletAddress?.slice(0, 11)}...
                              {item?.walletAddress?.slice(
                                item?.walletAddress?.length - 3,
                                item?.walletAddress?.length
                              )}{" "}
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className={copy === index && "copy-button "}
                              >
                                <path
                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              {copy === index && (
                                <p className="copy-text">Copied</p>
                              )}
                            </span>
                          ) : (
                            <>
                              {item?.walletAddress && (
                                <>
                                  <CopyToClipboard
                                    text={item?.walletAddress}
                                    onCopy={(e) => textCopiedFun(index)}
                                  >
                                    <span className="adadasdasdasdasdasdsad">
                                      {item?.walletAddress?.slice(0, 11)}...
                                      {item?.walletAddress?.slice(
                                        item?.walletAddress?.length - 3,
                                        item?.walletAddress?.length
                                      )}{" "}
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        className={
                                          copy === index && "copy-button "
                                        }
                                      >
                                        <path
                                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </CopyToClipboard>
                                  {copy === index && (
                                    <p className="copy-text">Copied</p>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </td>
                    <td>{convertToTitleCase(item?.role)}</td>
                    {/* <td>
                      {copy ? (
                        <span className="adadasdasdasdasdasdsad">
                          {item?.walletAddress?.slice(0, 11)}...
                          {item?.walletAddress?.slice(
                            item?.walletAddress?.length - 3,
                            item?.walletAddress?.length
                          )}{" "}
                          <svg
                            style={{ cursor: "pointer" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            className=""
                          >
                            <path
                              d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      ) : (
                        <>
                          {item?.walletAddress && (
                            <CopyToClipboard
                              text={item?.walletAddress}
                              onCopy={textCopiedFun2}
                            >
                              <span className="adadasdasdasdasdasdsad">
                                {item?.walletAddress?.slice(0, 11)}...
                                {item?.walletAddress?.slice(
                                  item?.walletAddress?.length - 3,
                                  item?.walletAddress?.length
                                )}{" "}
                                <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className=""
                                >
                                  <path
                                    d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                            </CopyToClipboard>
                          )}
                        </>
                      )}
                    </td> */}
                    {/* <td>
                      {item?.walletAddress?.slice(0, 11)}...
                      {item?.walletAddress?.slice(
                        item?.walletAddress?.length - 3,
                        item?.walletAddress?.length
                      )}{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.walletAddress);
                        }}
                        src="\assets\copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </td> */}

                    <td>
                      {parseFloat(
                        item?.totalDopDirect?.toFixed(2)
                      )?.toLocaleString()}{" "}
                      Tokens
                    </td>

                    <td>
                      {usdPrice ? (
                        <div className="twice-text">
                          <>
                            <h6>
                              $
                              {(
                                item?.myEarningsEth * usdPrice +
                                item?.myEarningsUsdt +
                                item?.myEarningsUsdc +
                                item?.myEarningsWbtc * btcPrice +
                                item?.myEarningsPepe * pepePrice +
                                item?.myEarningsUni * uniPrice +
                                item?.myEarningsLink * linkPrice +
                                item?.myEarningsDopToken * dopPrice +
                                item?.myEarningsTomi * tomiPrice +
                                item?.myEarningsGems * gemsPrice
                              )?.toFixed(4)}
                            </h6>
                            <p>
                              ({item?.myEarningsEth?.toFixed(5)} ETH +{" "}
                              {item?.myEarningsUsdt?.toFixed(4)} USDT +{" "}
                              {item?.myEarningsUsdc?.toFixed(4)} USDC +{" "}
                              {item?.myEarningsDopToken?.toFixed(2)} DOP +{" "}
                              {item?.myEarningsWbtc?.toFixed(6)} WBTC +{" "}
                              {item?.myEarningsPepe?.toFixed(2)} PEPE +{" "}
                              {item?.myEarningsLink?.toFixed(2)} LINK +{" "}
                              {item?.myEarningsUni?.toFixed(2)} UNI +{" "}
                              {item?.myEarningsTomi?.toFixed(2)} TOMI +{" "}
                              {item?.myEarningsGems?.toFixed(2)} GEMS)
                            </p>
                          </>
                        </div>
                      ) : (
                        <div className="twice-text">
                          <>
                            <h6>$0</h6>
                            <p>
                              (0 ETH + 0 USDT + 0 USDC + 0 DOP + 0 PEPE + 0 LINK
                              + 0 UNI + 0 TOMI + 0 GEMS)
                            </p>
                          </>
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="twice-text">
                        <>
                          <h6>${item?.totalAmountInUsd?.toFixed(2)}</h6>
                          <p>
                            ({item?.totalEth?.toFixed(5)} ETH +{" "}
                            {item?.totalUsdt?.toFixed(4)} USDT +{" "}
                            {item?.totalUsdc?.toFixed(4)} USDC +{" "}
                            {item?.totalDopToken?.toFixed(2)} DOP +{" "}
                            {item?.totalWbtc?.toFixed(2)} WBTC +{" "}
                            {item?.totalPepe?.toFixed(2)} PEPE +{" "}
                            {item?.totalLink?.toFixed(2)} LINK +{" "}
                            {item?.totalUni?.toFixed(2)} UNI +{" "}
                            {item?.totalTomi?.toFixed(2)} TOMI +{" "}
                            {item?.totalGems?.toFixed(2)} GEMS)
                          </p>
                        </>
                      </div>
                    </td>
                    {sub != true ? (
                      <td>
                        <div class="dropdown">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="\assets\more-icon.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => {
                                  setRoutes(true);
                                  setUserDel(item);
                                  setBlockUnblock(item?.isDeleted);
                                }}
                                class="dropdown-item"
                                href="#"
                              >
                                <img
                                  src="\assets\viewdetail.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                                View Details
                              </a>
                            </li>
                            {/* <li><a class="dropdown-item" href="#"><img src="\assets\edit.svg" alt="img" className="img-fluid" />Edit</a></li> */}
                            <li>
                              <a
                                onClick={() => {
                                  setShow(true);
                                  setUserDel(item);
                                  setBlockUnblock(item?.isDeleted);
                                }}
                                class="dropdown-item"
                                href="#"
                              >
                                {item?.isDeleted ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                  >
                                    <path
                                      d="M15.7039 11.7518H16.1206C16.3973 11.7518 16.6614 11.8618 16.8573 12.0568C16.9751 12.1707 17.1329 12.2337 17.2968 12.2324C17.4607 12.2311 17.6175 12.1654 17.7334 12.0496C17.8493 11.9337 17.9151 11.777 17.9166 11.6131C17.9181 11.4493 17.8552 11.2914 17.7414 11.1734C17.3114 10.7434 16.7281 10.5018 16.1206 10.5018H15.7939C15.4855 10.4896 15.1778 10.54 14.8893 10.6497C14.6008 10.7594 14.3374 10.9263 14.115 11.1403C13.8926 11.3543 13.7157 11.6111 13.5949 11.8952C13.4741 12.1792 13.412 12.4848 13.4123 12.7934V14.0434C13.4123 14.2092 13.4781 14.3682 13.5953 14.4854C13.7125 14.6026 13.8715 14.6684 14.0373 14.6684C14.203 14.6684 14.362 14.6026 14.4792 14.4854C14.5964 14.3682 14.6623 14.2092 14.6623 14.0434V12.7934C14.6627 12.5173 14.7726 12.2526 14.9678 12.0574C15.1631 11.8621 15.4278 11.7522 15.7039 11.7518Z"
                                      fill="#28E664"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M18.75 17.7967V14.88C18.75 14.4933 18.5963 14.1223 18.3229 13.8488C18.0494 13.5753 17.6784 13.4217 17.2917 13.4217H13.5417C13.1549 13.4217 12.784 13.5753 12.5105 13.8488C12.237 14.1223 12.0833 14.4933 12.0833 14.88V17.7967C12.0833 18.6025 12.7367 19.255 13.5417 19.255H17.2917C17.6784 19.255 18.0494 19.1014 18.3229 18.8279C18.5963 18.5544 18.75 18.1835 18.75 17.7967ZM15 16.9634H15.8333C15.9991 16.9634 16.1581 16.8975 16.2753 16.7803C16.3925 16.6631 16.4583 16.5041 16.4583 16.3384C16.4583 16.1726 16.3925 16.0136 16.2753 15.8964C16.1581 15.7792 15.9991 15.7134 15.8333 15.7134H15C14.8342 15.7134 14.6753 15.7792 14.5581 15.8964C14.4408 16.0136 14.375 16.1726 14.375 16.3384C14.375 16.5041 14.4408 16.6631 14.5581 16.7803C14.6753 16.8975 14.8342 16.9634 15 16.9634Z"
                                      fill="#28E664"
                                    />
                                    <path
                                      d="M9.79166 10.9031C12.323 10.9031 14.375 8.85109 14.375 6.31978C14.375 3.78848 12.323 1.73645 9.79166 1.73645C7.26036 1.73645 5.20833 3.78848 5.20833 6.31978C5.20833 8.85109 7.26036 10.9031 9.79166 10.9031Z"
                                      fill="#28E664"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.5025 18.8298C11.3358 18.5062 11.2492 18.1472 11.25 17.7831V14.8665C11.25 14.1548 12.094 13.4891 12.6032 13.0691V12.7533C12.6032 12.4191 12.6565 12.09 12.759 11.7783C11.9492 11.6531 10.6111 11.6205 9.79168 11.6215C7.02335 11.6215 4.57252 12.314 3.05085 13.3515C1.89002 14.1431 1.25002 15.149 1.25002 16.2048V17.4131C1.25046 17.7887 1.39985 18.1488 1.66543 18.4144C1.93102 18.68 2.29109 18.8294 2.66668 18.8298H11.5025Z"
                                      fill="#28E664"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.99999 10.9167C12.5313 10.9167 14.5833 8.86464 14.5833 6.33333C14.5833 3.80203 12.5313 1.75 9.99999 1.75C7.46868 1.75 5.41666 3.80203 5.41666 6.33333C5.41666 8.86464 7.46868 10.9167 9.99999 10.9167Z"
                                      fill="#FE0000"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.7108 18.8434C11.5441 18.5197 11.4575 18.1608 11.4583 17.7967V14.88C11.4583 14.1684 11.7825 13.5325 12.2917 13.1125V12.7967C12.2917 12.4625 12.345 12.1334 12.4475 11.8217C11.6377 11.6965 10.8194 11.6341 10 11.635C7.23168 11.635 4.78084 12.3275 3.25918 13.365C2.09834 14.1567 1.45834 15.1625 1.45834 16.2184V17.4267C1.45878 17.8023 1.60818 18.1624 1.87376 18.4279C2.13934 18.6935 2.49942 18.8429 2.87501 18.8434H11.7108Z"
                                      fill="#FE0000"
                                    />
                                    <path
                                      d="M15.8333 11.755H15.4167C15.1407 11.7559 14.8762 11.8659 14.6811 12.0611C14.4859 12.2562 14.3759 12.5207 14.375 12.7967V14.0467C14.375 14.2124 14.3092 14.3714 14.1919 14.4886C14.0747 14.6058 13.9158 14.6717 13.75 14.6717C13.5842 14.6717 13.4253 14.6058 13.3081 14.4886C13.1908 14.3714 13.125 14.2124 13.125 14.0467V12.7967C13.1254 12.189 13.367 11.6064 13.7967 11.1767C14.2264 10.747 14.809 10.5054 15.4167 10.505H15.7433C16.0518 10.4929 16.3595 10.5432 16.648 10.6529C16.9365 10.7626 17.1999 10.9295 17.4223 11.1435C17.6447 11.3576 17.8216 11.6143 17.9424 11.8984C18.0631 12.1825 18.1252 12.488 18.125 12.7967V14.0467C18.125 14.2124 18.0592 14.3714 17.9419 14.4886C17.8247 14.6058 17.6658 14.6717 17.5 14.6717C17.3342 14.6717 17.1753 14.6058 17.0581 14.4886C16.9408 14.3714 16.875 14.2124 16.875 14.0467V12.7967C16.8746 12.5205 16.7647 12.2558 16.5694 12.0606C16.3742 11.8653 16.1095 11.7554 15.8333 11.755Z"
                                      fill="#FE0000"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M18.9583 17.7967V14.88C18.9583 14.4933 18.8047 14.1223 18.5312 13.8488C18.2577 13.5753 17.8868 13.4217 17.5 13.4217H13.75C13.3632 13.4217 12.9923 13.5753 12.7188 13.8488C12.4453 14.1223 12.2917 14.4933 12.2917 14.88V17.7967C12.2917 18.6025 12.945 19.255 13.75 19.255H17.5C17.8868 19.255 18.2577 19.1014 18.5312 18.8279C18.8047 18.5544 18.9583 18.1835 18.9583 17.7967ZM15 15.9217V16.755C15 16.9208 15.0658 17.0798 15.183 17.197C15.3003 17.3142 15.4592 17.38 15.625 17.38C15.7908 17.38 15.9497 17.3142 16.0669 17.197C16.1841 17.0798 16.25 16.9208 16.25 16.755V15.9217C16.25 15.7559 16.1841 15.597 16.0669 15.4798C15.9497 15.3625 15.7908 15.2967 15.625 15.2967C15.4592 15.2967 15.3003 15.3625 15.183 15.4798C15.0658 15.597 15 15.7559 15 15.9217Z"
                                      fill="#FE0000"
                                    />
                                  </svg>
                                )}

                                {item?.isDeleted ? "Unblock" : "Block"}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>

       
        </div>
        {pagination ? (
            <div className="footer-content mt-2">
              <div className="left-f">
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <nav aria-label="Page navigation example">
                      <ul class="text-center">
                        <ReactPaginate
                          previousLabel={
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    
                              <path d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z" fill="#329879"/>
                              <path d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z" fill="#329879"/>
                              <path d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z" fill="#329879"/>
                      
                          </svg>
                          }
                          nextLabel={
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z" fill="#329879"/>
                            <path d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z" fill="#329879"/>
                            <path d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z" fill="#329879"/>
                          </svg>
                          }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </ul>
                    </nav>
                  </ul>
                </nav>
              </div>
            </div>
          ) : null}
        <div className="mobiledesignss  d-none">
          {agents?.map((item, index) => {
            return (
              <div className="new-accordionmobile dsuygfdsyufgsdyuf d-none">
                <div className="upper-body">
                  <div className="inner-text">
                    <h6>Date and time</h6>
                    <p> {convertDateFormat(item?.createdAt)?.slice(0, 10)}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Leader</h6>
                    <p>{item?.name}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Role</h6>
                    <p>{convertToTitleCase(item?.role)}</p>
                  </div>
                  <div className="inner-textt">
                    <p>Wallet Address</p>
                    {copy ? (
                      <p className="adadasdasdasdasdasdsad">
                        {item?.walletAddress?.slice(0, 11)}...
                        {item?.walletAddress?.slice(
                          item?.walletAddress?.length - 3,
                          item?.walletAddress?.length
                        )}
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          className=""
                        >
                          <path
                            d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                    ) : (
                      <>
                        {item?.walletAddress && (
                          <CopyToClipboard
                            text={item?.walletAddress}
                            onCopy={(e) => textCopiedFun(index)}
                          >
                            <p className="adadasdasdasdasdasdsad">
                              {item?.walletAddress?.slice(0, 11)}...
                              {item?.walletAddress?.slice(
                                item?.walletAddress?.length - 3,
                                item?.walletAddress?.length
                              )}
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className=""
                              >
                                <path
                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </p>
                          </CopyToClipboard>
                        )}
                      </>
                    )}
                  </div>
                  {/* <div className="inner-text">
                    <h6>Wallet Address</h6>
                    <p className="textright">
                      {item?.walletAddress?.slice(0, 11)}...
                      {item?.walletAddress?.slice(
                        item?.walletAddress?.length - 3,
                        item?.walletAddress?.length
                      )}
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.walletAddress);
                        }}
                        src="\assets\copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </p>
                  </div> */}
                </div>
                <div className="bottom-body">
                  <Accordion>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        More Info{" "}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="inner-textt">
                          <p>tokens Purchased</p>

                          <h6 className="textright">
                            {parseFloat(
                              item?.totalDopDirect?.toFixed(2)
                            ).toLocaleString()}{" "}
                            Tokens
                          </h6>
                        </div>
                        <div className="inner-textt">
                          <p>Leader Earnings</p>

                          {usdPrice ? (
                            <div className="twice-text">
                              <>
                                <h6>
                                  $
                                  {(
                                    item?.myEarningsEth * usdPrice +
                                    item?.myEarningsUsdt +
                                    item?.myEarningsUsdc +
                                    item?.myEarningsWbtc * btcPrice +
                                    item?.myEarningsPepe * pepePrice +
                                    item?.myEarningsUni * uniPrice +
                                    item?.myEarningsLink * linkPrice +
                                    item?.myEarningsDopToken * dopPrice +
                                    item?.myEarningsTomi * tomiPrice +
                                    item?.myEarningsGems * gemsPrice
                                  )?.toFixed(4)}
                                </h6>
                                <p>
                                  ({item?.myEarningsEth?.toFixed(5)} ETH +{" "}
                                  {item?.myEarningsUsdt?.toFixed(4)} USDT +{" "}
                                  {item?.myEarningsUsdc?.toFixed(4)} USDC +{" "}
                                  {item?.myEarningsDopToken?.toFixed(2)} DOP +{" "}
                                  {item?.myEarningsWbtc?.toFixed(2)} WBTC +{" "}
                                  {item?.myEarningsPepe?.toFixed(2)} PEPE +{" "}
                                  {item?.myEarningsLink?.toFixed(2)} LINK +{" "}
                                  {item?.myEarningsUni?.toFixed(2)} UNI +{" "}
                                  {item?.myEarningsTomi?.toFixed(2)} TOMI +{" "}
                                  {item?.myEarningsGems?.toFixed(2)} GEMS)
                                </p>
                              </>
                            </div>
                          ) : (
                            <div className="twice-text">
                              <>
                                <h6>$0</h6>
                                <p>
                                  {" "}
                                  (0 ETH + 0 USDT + 0 USDC + 0 DOP + 0 PEPE + 0
                                  LINK + 0 UNI + 0 TOMI + 0 GEMS)
                                </p>
                              </>
                            </div>
                          )}
                        </div>
                        {/* totalPurchaseUsdt */}
                        <div className="inner-textt">
                          <p>Total Purchases</p>

                          <div className="twice-text">
                            <>
                              <h6>${item?.totalAmountInUsd?.toFixed(4)}</h6>
                              <p>
                                ({item?.totalEth?.toFixed(5)} ETH +{" "}
                                {item?.totalUsdt?.toFixed(4)} USDT +{" "}
                                {item?.totalUsdc?.toFixed(4)} USDC +{" "}
                                {item?.totalDopToken?.toFixed(2)} DOP +{" "}
                                {item?.totalWbtc?.toFixed(2)} WBTC +{" "}
                                {item?.totalPepe?.toFixed(2)} PEPE +{" "}
                                {item?.totalLink?.toFixed(2)} LINK +{" "}
                                {item?.totalUni?.toFixed(2)} UNI +{" "}
                                {item?.totalTomi?.toFixed(2)} TOMI +{" "}
                                {item?.totalGems?.toFixed(2)} GEMS)
                              </p>
                            </>
                          </div>
                        </div>
                        {sub != true ? (
                          <div className="inner-textt">
                            <button
                              onClick={() => {
                                setRoutes(true);
                                setUserDel(item);
                                setBlockUnblock(item?.isDeleted);
                              }}
                              className="view-btn"
                            >
                              View Details
                            </button>
                          </div>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              // <Accordion.Item key={index} eventKey={index}>
              //   <Accordion.Header>
              //     <div className="accheadermain">
              //       <p className="acctext">
              //         {convertDateFormat(item?.createdAt)?.slice(0, 10)}
              //       </p>
              //       <p className="acctext">{item?.name}</p>
              //       <p className="acctext">
              //         {convertToTitleCase(item?.role)}
              //       </p>
              //     </div>
              //   </Accordion.Header>
              //   <Accordion.Body>
              //     <div className="acctexts">
              //       <h6 className="textleft">Wallet Address</h6>
              //       <p className="textright">
              //         {item?.walletAddress?.slice(0, 11)}...
              //         {item?.walletAddress?.slice(
              //           item?.walletAddress?.length - 3,
              //           item?.walletAddress?.length
              //         )}
              //         <img
              //           style={{ cursor: "pointer" }}
              //           onClick={() => {
              //             navigator.clipboard.writeText(item?.walletAddress);
              //           }}
              //           src="\assets\copy.svg"
              //           alt="img"
              //           className="img-fluid"
              //         />
              //       </p>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">DOP Purchased</h6>
              //       <p className="textright">{item?.totalDop} DOP</p>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">Leader Earning</h6>
              //       <div className="twice-text">
              //         <h6>
              //           $
              //           {(
              //             item?.myEarningsEth * usdPrice +
              //             item?.myEarningsUsdt
              //           )?.toFixed(4)}
              //         </h6>
              //         <p>
              //           ({item?.myEarningsEth} ETH + {item?.myEarningsUsdt}{" "}
              //           USDT)
              //         </p>
              //       </div>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">Total Purchases</h6>
              //       <div className="twice-text">
              //         <h6>
              //           {(
              //             item?.totalEth * usdPrice +
              //             item?.totalUsdt
              //           )?.toFixed(4)}{" "}
              //         </h6>
              //         <p>
              //           ({item?.totalEth} ETH + {item?.totalUsdt} USDT){" "}
              //         </p>
              //       </div>
              //     </div>
              //     <div className="acctexts">
              //       <button
              //          onClick={() => {
              //           setRoutes(true);
              //           setUserDel(item);
              //         }}
              //         className="view-btn"
              //       >
              //         View Details
              //       </button>
              //     </div>
              //   </Accordion.Body>
              // </Accordion.Item>
            );
          })}
          {pagination ? (
            <div className="footer-content mt-2">
              <div className="left-f">
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
                {/* <h6>SHOWING 1-10 OF 145</h6> */}
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <nav aria-label="Page navigation example">
                      <ul class="text-center">
                        <ReactPaginate
                          previousLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M10 13L5 8L10 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          nextLabel={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M5 13L10 8L5 3"
                                stroke="#8C8C8C"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </ul>
                    </nav>
                  </ul>
                </nav>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default DataTable;
