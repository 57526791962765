import { combineReducers } from "redux";

import { ChartReducer } from "../Reducers/Index";
import { UserReducer } from "../Reducers/Index";

const rootReducer = combineReducers({
  chart: ChartReducer,
  user: UserReducer,
});

export default rootReducer;
