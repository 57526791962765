let Environment = {
  PreSale: "0x20361bC401EA01e5d6E01596c445Af1a48e1171b",
  Claim: "0x27E00382b0C3eCd74d1505e8CD73706d79D0Bd54",
  InstitutionPreSale: "0x09e4f3E65ce7c16566DdFA0D860D4d95a26698ca",
  Graph: "https://api.studio.thegraph.com/query/63987/incentive-main/v1.1.1.1",
  institutionGraph:
    "https://api.studio.thegraph.com/query/63987/incentive-institution-mainnet/01",
  ///////// LEIA

  LEIAPreSale: "0x3aEdC9075A8238A68BEc28495ea566f9abcA2840",
  LEIAClaim: "0xB107140C5737Bfa6dBBe46db6b359Caf6e51524D",
  LEIAInstitutionPreSale: "0x72312fAfd5f58C8D44B1769b09A0F879ce93d181",
  LEIAGraph:
    "https://api.studio.thegraph.com/query/63987/lea-mainnet/version/latest",
  LEIAinstitutionGraph:
    "https://api.studio.thegraph.com/query/63987/incentive-institution-mainnet/01",

  ///////// OLYMPUS

  OlympusPreSale: "0xaDF3202480bD62F3dB150E27A0F811f999F253B9",
  OlympusClaim: "0x1a11428b6F7903024B79aa7843d59895c817a3ad",
  OlympusInstitutionPreSale: "0x72312fAfd5f58C8D44B1769b09A0F879ce93d181",
  OlympusGraph: "https://api.studio.thegraph.com/query/63987/olympus-main/v-1",
  OlympusinstitutionGraph:
    "https://api.studio.thegraph.com/query/63987/incentive-institution-mainnet/01",

  ///////// NFA

  NFAPreSale: "0x8c61465F51C95635a43E4D0094f2329Ff5989992",
  NFAClaim: "0x84fD533E83B0b869034F3675DE0ABE00eE98a179",
  NFAInstitutionPreSale: "0x72312fAfd5f58C8D44B1769b09A0F879ce93d181",
  NFAGraph:
    "https://api.studio.thegraph.com/query/63987/nfa-mainnet/version/latest",
  NFAinstitutionGraph:
    "https://api.studio.thegraph.com/query/63987/incentive-institution-mainnet/01",

  ///////// ASTRO NOVA

  AstroNovaPreSale: "0x24EF0ccaF8F3B4671FcE82005023E85120f6c026",
  AstroNovaClaim: "0x1890cF752f1be4D60f3b22168c7A6aFEbd9D74C7",
  AstroNovaInstitutionPreSale: "0x72312fAfd5f58C8D44B1769b09A0F879ce93d181",
  AstroNovaGraph:
    "https://subgraph.satsuma-prod.com/323ce71d2cc0/usman--186639/astro-nova-live/api",
  AstroNovainstitutionGraph:
    "https://api.studio.thegraph.com/query/63987/incentive-institution-mainnet/01",

  ///////// MINERS CLAIMS (TESTNET FOR NOW)
  MinersClaim: "0x8803eE4671159e6a07067da6ee4789655125C089",
  MinersGraph:
    "https://api.studio.thegraph.com/query/63987/miners-node-staging/version/latest",

  /////// GEMS MAINNET

  GemsGraph:
    "https://api.studio.thegraph.com/query/63987/gems-presale-main/0.4.1", //MAINNET
  GemsClaim: "0x96d7e90E6176466a0D882fc8BA6436021F1AeB06", //MAINNET
  GemsPreSale: "0x6236e15ab57bAA2d1465660Ef4Ec3B0A8D071090", //MAINNET

  busd: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  wbtc: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
  pepe: "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
  link: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
  uni: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
  dop: "0x97A9a15168C22B3C137E6381037E1499C8ad0978",
  gems: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc", //MAINNET
};
export default Environment;
