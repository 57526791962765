import React, { useState, useEffect } from "react";
import DataTable from "./DataTable/DataTable";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import { CSVLink } from "react-csv";
import moment from "moment";

const LowCreditError = ({ setindexwait }) => {
  const functiontogetback = () => {
    localStorage.setItem("indexvalue", 0);
    setindexwait(0);
  };

  const val = localStorage.getItem("accessToken");
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [processedData, setProcessedData] = useState([]);

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    getData();
  }, [page]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };
  const getData = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Api_Url}/users/denied-transaction-count?limit=10&offset=${
        page + 1
      }`,

      headers: {
        Authorization: "Bearer " + val,
      },
    };

    let dynamicQuery = config.url;

    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        setTableData(res?.data?.data?.data?.data);
        setPageCount(res?.data?.data?.data?.pages);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const getCSVData = async (id) => {
    var config = {
      method: "get",
      url: `${Api_Url}/denied-buyers/${id}`,

      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then(function (res) {
        setLoader(false);
        const resData = res?.data?.data;

        const processedDataTemp = resData.map((item) => ({
          agentRef: item.agentRef,
          buyerEmail: item.buyerEmail,
          createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
          updatedAt: moment(item.updatedAt).format("DD-MM-YYYY"),
        }));
        const headers = [
          { label: "Agent Reference", key: "agentRef" },
          { label: "Buyer Email", key: "buyerEmail" },
          { label: "Created At", key: "createdAt" },
          { label: "Updated At", key: "updatedAt" },
        ];
        downloadCSV(resData);
        setHeaders(headers);
        setProcessedData(processedDataTemp);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };
  const downloadCSV = (data) => {
    if (!data || data.length === 0) {
      console.error("No data to download");
      return;
    }

    const headers = ["Buyer Email", "Accessed At"];

    const csvData = data.map((item) => {
      const { _id, __v, agentRef, createdAt, updatedAt, ...rest } = item; // Exclude _id, __v, createdAt, and updatedAt fields
      const formattedCreatedAt = new Date(createdAt).toLocaleDateString(
        "en-GB"
      ); // Format createdAt to DD-MM-YYYY
      const formattedUpdatedAt = new Date(updatedAt).toLocaleDateString(
        "en-GB"
      ); // Format updatedAt to DD-MM-YYYY
      return Object.values({
        ...rest,
        createdAt: formattedCreatedAt,
      });
    });

    csvData.unshift(headers); // Add headers as the first row

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "ErrorsData.csv");
    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <section className="salesround">
        <div className="custom-container">
          <button
            class="btn-back guvxgyvxvtxx"
            onClick={() => functiontogetback()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                fill="#FFF"
              ></path>
              <path
                d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                fill="#FFF"
              ></path>
            </svg>{" "}
            Back
          </button>
          <div className="main-heading">
            <h6>Low Credit Errors</h6>
          </div>

          <DataTable
            handlePageChange={handlePageChange}
            tableData={tableData}
            page={page}
            pageCount={pageCount}
            getCSVData={getCSVData}
            loader={loader}
            headers={headers}
            processedData={processedData}
          />
        </div>
      </section>
    </>
  );
};

export default LowCreditError;
