import React, { useState, useEffect } from "react";
import "./insurance.scss";
import DataTable from "./DataTable/DataTable";
import DataTable2 from "./DataTable2/DataTable";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Api_Url } from "../../../utils/ApiUrl";
import { useHistory } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";

const InsuranceDetail = ({
  user,
  setindexwait,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
}) => {
  const tok = localStorage?.getItem("accessToken");
  const [project, setProject] = useState("LEIA");
  const [selectedItem, setSelectedItem] = useState({
    imgSrc: "/assets/leia.svg",
    text: "LEIA",
  });
  const [insurancePurchasedApiCalled, setInsurancePurchasedApiCalled] =
    useState(false);
  const [insuranceClaimedApiCalled, setInusranceClaimedApiCalled] =
    useState(false);

  // const [usdPrice, setUsdPrice] = useState(0);
  const [insurancePurchased, setInsurancePurchased] = useState([]);
  const [insuranceClaimed, setInsuranceClaimed] = useState([]);

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState("");

  const [page1, setPage1] = useState(0);
  const [pageCount1, setPageCount1] = useState(0);

  const [search1, setSearch1] = useState("");

  const [searchBy, setSearchBy] = useState("name");

  const [typeFilter, setTypeFilter] = useState("");
  const [orderField, setOrderField] = useState("transactionTime");
  const [orderDirection, setOrderDirection] = useState("-1");

  const [orderField1, setOrderField1] = useState("transactionTime");
  const [orderDirection1, setOrderDirection1] = useState("-1");

  const [key, setKey] = useState("transactions");

  const history = useHistory();

  const userObj = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (usdPrice) {
      getInsurancePurchasedData();
    }
  }, [
    project,
    usdPrice,
    user,
    search,
    typeFilter,
    orderField,
    orderDirection,
    page,
  ]);

  useEffect(() => {
    if (usdPrice && key == "leaders") {
      getInsuranceClaimedData();
    }
  }, [
    usdPrice,
    user,
    search,
    typeFilter,
    orderField1,
    orderDirection1,
    page1,
    key,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const handlePageChange1 = (e) => {
    const selectedPage = e.selected;

    setPage1(selectedPage);
  };

  const getInsurancePurchasedData = () => {
    setInsurancePurchased([]);
    setInsurancePurchasedApiCalled(false);

    var config = {
      method: "get",
      url: `${Api_Url}/users/insured-transaction?privateSale=${project}&limit=10&offset=${
        page + 1
      }`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };
    let dynamicQuery = config.url;
    if (search) {
      dynamicQuery += `&search=${search}`;
    }
    if (typeFilter) {
      dynamicQuery += `&leaderType=${typeFilter}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }

    config.url = dynamicQuery;

    axios(config)
      .then(function (res) {
        console.log("trx data: ", res?.data?.data?.transactions);
        setInsurancePurchased(res?.data?.data?.transactions);
        setPageCount(res?.data?.data?.pages);
        setInsurancePurchasedApiCalled(true);

        // setLoader(false);
      })
      .catch(function (error) {
        // setLoader(false);
        setInsurancePurchasedApiCalled(true);
      });
  };

  const getInsuranceClaimedData = () => {
    setInsuranceClaimed([]);
    setInusranceClaimedApiCalled(false);

    var config = {
      method: "get",
      url: `${Api_Url}/users/insured-claim-transaction?limit=10&offset=${
        page1 + 1
      }`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };
    let dynamicQuery = config.url;
    if (search1) {
      dynamicQuery += `&search=${search1}`;
    }
    if (typeFilter) {
      dynamicQuery += `&leaderType=${typeFilter}`;
    }
    if (orderField1) {
      dynamicQuery += `&orderField=${orderField1}&orderDirection=${orderDirection1}`;
    }

    config.url = dynamicQuery;

    axios(config)
      .then(function (res) {
        console.log("trx data: ", res?.data?.data?.transactions);
        setInsuranceClaimed(res?.data?.data?.transactions);
        setPageCount1(res?.data?.data?.pages);
        setInusranceClaimedApiCalled(true);

        // setLoader(false);
      })
      .catch(function (error) {
        // setLoader(false);
        setInusranceClaimedApiCalled(true);
      });
  };

  const functiontogetback = () => {
    localStorage.setItem("indexvalue", 0);
    setindexwait(0);
  };

  const handleItemClick = (imgSrc, text) => {
    setSelectedItem({ imgSrc, text });
    setProject(text);
    // localStorage.setItem("project", text);
  };
  return (
    <>
      <section className="alltransaction insurance-detail">
        <div className="custom-container">
          <button
            class="btn-back guvxgyvxvtxx"
            onClick={() => functiontogetback()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                fill="black"
              ></path>
              <path
                d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                fill="black"
              ></path>
            </svg>{" "}
            Back
          </button>

          <div className="main-heading">
            <h6>Insurance Details</h6>
            <div>
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="projectmain">
                    {selectedItem.imgSrc && (
                      <img
                        src={selectedItem.imgSrc}
                        alt="projectimg"
                        className="projectimg"
                        width={24}
                      />
                    )}
                    <p className="projectpara">{selectedItem.text}</p>
                  </div>
                  <img
                    src="/assets/arrow-down.svg"
                    alt="arrow-down"
                    className="img-fluid"
                  />
                </button>
                <ul className="dropdown-menu">
                  {/* <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleItemClick("", "All Projects")}
                    >
                      All Projects
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleItemClick("/assets/leia.svg", "LEIA")
                      }
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/leia.svg"
                          alt="projectimg"
                          className="projectimg"
                          width={24}
                        />
                        <p className="projectpara">Leia</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleItemClick("/assets/olympus.svg", "OLYMPUS")
                      }
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/olympus.svg"
                          alt="projectimg"
                          className="projectimg"
                          width={24}
                        />
                        <p className="projectpara">Olypmus</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        handleItemClick(
                          "/assets/incentiv-new-logo.svg",
                          "INCENTIV"
                        )
                      }
                    >
                      <div className="projectmain">
                        <img
                          src="/assets/incentiv-new-logo.svg"
                          alt="projectimg"
                          className="projectimg"
                        />
                        <p className="projectpara">Incentiv</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Tabs
            defaultActiveKey="transactions"
            id="uncontrolled-tab-example"
            className="viewdetail-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="transactions" title="Insurance Purchased">
              <div className="main-heading111">
                <div className="rightside-content">
                  <div
                    className={
                      userObj?.role == "mini_agent"
                        ? "custom-option-field w-100"
                        : "custom-option-field w-100"
                    }
                  >
                    {/* <form onSubmit={(e) => GetSearch(e)}> */}
                    <input
                      onChange={(e) => {
                        if (page != 0) {
                          setPage(0);
                        }
                        setSearch(e.target.value);
                      }}
                      type="search"
                      placeholder="Search by Wallet..."
                    />
                    {/* </form> */}
                    <img
                      style={{ cursor: "pointer" }}
                      src="\assets\search-icon.svg"
                      alt="img"
                      className="img-fluid search-icon"
                      // onClick={() => GetSearch()}
                    />
                    {/* <div class="dropdown transaction-inner-drop">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {searchBy == "name"
                          ? "Name"
                          : searchBy == "accessCodeFilter"
                          ? "Code"
                          : searchBy == "by"
                          ? "Wallet"
                          : ""}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid ms-1"
                        />
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => setSearchBy("name")}
                          >
                            By Name
                          </a>
                        </li>

                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => setSearchBy("accessCodeFilter")}
                          >
                            By Code
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => setSearchBy("by")}
                          >
                            By Wallet
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
              {insurancePurchasedApiCalled ? (
                <>
                  {insurancePurchased?.length > 0 ? (
                    <DataTable
                      page={page}
                      handlePageClick={handlePageChange}
                      setSearch={setSearch}
                      search={search}
                      usdPrice={usdPrice}
                      btcPrice={btcPrice}
                      pepePrice={pepePrice}
                      linkPrice={linkPrice}
                      uniPrice={uniPrice}
                      dopPrice={dopPrice}
                      insurancePurchased={insurancePurchased}
                      // setTransactions={setTransactions}
                      pageCount={pageCount}
                      user={user}
                      setOrderDirection={setOrderDirection}
                      setOrderField={setOrderField}
                    />
                  ) : (
                    <p
                      className=""
                      style={{
                        color: "#000",
                        textAlign: "center",
                        marginTop: 100,
                      }}
                    >
                      No data found!
                    </p>
                  )}
                </>
              ) : (
                <p
                  className=""
                  style={{ color: "#000", textAlign: "center", marginTop: 100 }}
                >
                  Loading...
                </p>
              )}

              <div className="footer-content mt-2">
                <div className="left-f">
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <nav aria-label="Page navigation example">
                        <ul class="text-center">
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#8C8C8C"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#8C8C8C"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={page}
                          ></ReactPaginate>
                        </ul>
                      </nav>
                    </ul>
                  </nav>
                </div>
              </div>
            </Tab>
            <Tab eventKey="leaders" title="Claim Details">
              <div className="main-heading111">
                <div className="rightside-content">
                  <div
                    className={
                      userObj?.role == "mini_agent"
                        ? "custom-option-field w-100"
                        : "custom-option-field w-100"
                    }
                  >
                    {/* <form onSubmit={(e) => GetSearch(e)}> */}
                    <input
                      onChange={(e) => {
                        if (page != 0) {
                          setPage1(0);
                        }
                        setSearch1(e.target.value);
                      }}
                      type="search"
                      placeholder="Search..."
                    />
                    {/* </form> */}
                    {/* <img
                      style={{ cursor: "pointer" }}
                      src="\assets\search-icon.svg"
                      alt="img"
                      className="img-fluid search-icon"
                      // onClick={() => GetSearch()}
                    />
                    <div class="dropdown transaction-inner-drop">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {searchBy == "name"
                          ? "Name"
                          : searchBy == "accessCodeFilter"
                          ? "Code"
                          : searchBy == "by"
                          ? "Wallet"
                          : ""}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid ms-1"
                        />
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => setSearchBy("name")}
                          >
                            By Name
                          </a>
                        </li>

                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => setSearchBy("accessCodeFilter")}
                          >
                            By Code
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => setSearchBy("by")}
                          >
                            By Wallet
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
              {insuranceClaimedApiCalled ? (
                <>
                  {insuranceClaimed?.length > 0 ? (
                    <DataTable2
                      page1={page1}
                      handlePageClick={handlePageChange1}
                      setSearch1={setSearch1}
                      search1={search1}
                      usdPrice={usdPrice}
                      btcPrice={btcPrice}
                      pepePrice={pepePrice}
                      linkPrice={linkPrice}
                      uniPrice={uniPrice}
                      dopPrice={dopPrice}
                      insuranceClaimed={insuranceClaimed}
                      // setTransactions={setTransactions}
                      pageCount1={pageCount1}
                      user={user}
                      setOrderDirection1={setOrderDirection1}
                      setOrderField={setOrderField1}
                    />
                  ) : (
                    <p
                      className=""
                      style={{
                        color: "#000",
                        textAlign: "center",
                        marginTop: 100,
                      }}
                    >
                      No data found!
                    </p>
                  )}
                </>
              ) : (
                <p
                  className=""
                  style={{ color: "#000", textAlign: "center", marginTop: 100 }}
                >
                  Loading...
                </p>
              )}

              <div className="footer-content mt-2">
                <div className="left-f">
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <nav aria-label="Page navigation example">
                        <ul class="text-center">
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#8C8C8C"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#8C8C8C"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount1}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange1}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={page1}
                          ></ReactPaginate>
                        </ul>
                      </nav>
                    </ul>
                  </nav>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default InsuranceDetail;
