import React, { useState, useEffect, useRef } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import { Accordion, Dropdown, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";
import moment from "moment";
const DataTable = ({
  handlePageChange,
  tableData,
  page,
  pageCount,
  getCSVData,
  loader,
  processedData,
  headers,
}) => {
  const [copy, setCopy] = useState(false);
  const csvLinkRef = useRef();

  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  useEffect(() => {
    if (processedData && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  }, [processedData]);
  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Leader
                    {/* <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Access Code
                    {/* <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Wallet Address
                    {/* <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Email Address
                    {/* <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div> */}
                  </div>
                </th>
                {/* <th>
                  <div className="parent-tag">
                    Total Allocation <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th> */}
                {/* <th>
                  <div className="parent-tag">
                    DOP Purchased{" "}
                    <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div>
                  </div>
                </th> */}
                <th>
                  <div className="parent-tag">
                    Error Count
                    {/* <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    User Emails
                    {/* <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div> */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData?.length > 0 ? (
                tableData?.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        <div className="twice-text">
                          <h6>{item?.name}</h6>
                          <p>{item?.role}</p>
                        </div>
                      </td>

                      <td>
                        {copy ? (
                          <span className="adadasdasdasdasdasdsad">
                            {item?.accessCode}

                            <svg
                              style={{ cursor: "pointer" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <path
                                d="M9.74726 8.49972L9.77365 10.9496C9.79563 12.9911 8.9878 13.8165 6.94626 13.8385L4.4964 13.8649C2.45485 13.8869 1.62944 13.0791 1.60745 11.0375L1.58107 8.58766C1.55909 6.54611 2.36691 5.7207 4.40846 5.69871L6.85832 5.67233C8.89987 5.65035 9.72528 6.45817 9.74726 8.49972Z"
                                stroke="#B1B1B1"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.2097 4.96237L13.236 7.41223C13.258 9.45378 12.4502 10.2792 10.4087 10.3012L9.76703 10.3081L9.74756 8.49985C9.72557 6.45831 8.90016 5.65048 6.85861 5.67246L5.05038 5.69194L5.04347 5.05031C5.02149 3.00876 5.82931 2.18335 7.87086 2.16136L10.3207 2.13498C12.3623 2.113 13.1877 2.92082 13.2097 4.96237Z"
                                stroke="#B1B1B1"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        ) : (
                          <>
                            {item?.accessCode && (
                              <>
                                <CopyToClipboard
                                  text={item?.accessCode}
                                  onCopy={(e) => textCopiedFun(index)}
                                >
                                  <span className="adadasdasdasdasdasdsad">
                                    {item?.accessCode}
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="16"
                                      viewBox="0 0 15 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M9.74726 8.49972L9.77365 10.9496C9.79563 12.9911 8.9878 13.8165 6.94626 13.8385L4.4964 13.8649C2.45485 13.8869 1.62944 13.0791 1.60745 11.0375L1.58107 8.58766C1.55909 6.54611 2.36691 5.7207 4.40846 5.69871L6.85832 5.67233C8.89987 5.65035 9.72528 6.45817 9.74726 8.49972Z"
                                        stroke="#B1B1B1"
                                        stroke-width="1.2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.2097 4.96237L13.236 7.41223C13.258 9.45378 12.4502 10.2792 10.4087 10.3012L9.76703 10.3081L9.74756 8.49985C9.72557 6.45831 8.90016 5.65048 6.85861 5.67246L5.05038 5.69194L5.04347 5.05031C5.02149 3.00876 5.82931 2.18335 7.87086 2.16136L10.3207 2.13498C12.3623 2.113 13.1877 2.92082 13.2097 4.96237Z"
                                        stroke="#B1B1B1"
                                        stroke-width="1.2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </CopyToClipboard>
                                {copy === index && (
                                  <p className="copy-text">Copied</p>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>

                      <td>
                        {copy ? (
                          <span className="adadasdasdasdasdasdsad">
                            {item?.walletAddress}

                            <svg
                              style={{ cursor: "pointer" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <path
                                d="M9.74726 8.49972L9.77365 10.9496C9.79563 12.9911 8.9878 13.8165 6.94626 13.8385L4.4964 13.8649C2.45485 13.8869 1.62944 13.0791 1.60745 11.0375L1.58107 8.58766C1.55909 6.54611 2.36691 5.7207 4.40846 5.69871L6.85832 5.67233C8.89987 5.65035 9.72528 6.45817 9.74726 8.49972Z"
                                stroke="#B1B1B1"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.2097 4.96237L13.236 7.41223C13.258 9.45378 12.4502 10.2792 10.4087 10.3012L9.76703 10.3081L9.74756 8.49985C9.72557 6.45831 8.90016 5.65048 6.85861 5.67246L5.05038 5.69194L5.04347 5.05031C5.02149 3.00876 5.82931 2.18335 7.87086 2.16136L10.3207 2.13498C12.3623 2.113 13.1877 2.92082 13.2097 4.96237Z"
                                stroke="#B1B1B1"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        ) : (
                          <>
                            {item?.walletAddress && (
                              <>
                                <CopyToClipboard
                                  text={item?.walletAddress}
                                  onCopy={(e) => textCopiedFun(index)}
                                >
                                  <span className="adadasdasdasdasdasdsad">
                                    {item?.walletAddress}
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="16"
                                      viewBox="0 0 15 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M9.74726 8.49972L9.77365 10.9496C9.79563 12.9911 8.9878 13.8165 6.94626 13.8385L4.4964 13.8649C2.45485 13.8869 1.62944 13.0791 1.60745 11.0375L1.58107 8.58766C1.55909 6.54611 2.36691 5.7207 4.40846 5.69871L6.85832 5.67233C8.89987 5.65035 9.72528 6.45817 9.74726 8.49972Z"
                                        stroke="#B1B1B1"
                                        stroke-width="1.2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.2097 4.96237L13.236 7.41223C13.258 9.45378 12.4502 10.2792 10.4087 10.3012L9.76703 10.3081L9.74756 8.49985C9.72557 6.45831 8.90016 5.65048 6.85861 5.67246L5.05038 5.69194L5.04347 5.05031C5.02149 3.00876 5.82931 2.18335 7.87086 2.16136L10.3207 2.13498C12.3623 2.113 13.1877 2.92082 13.2097 4.96237Z"
                                        stroke="#B1B1B1"
                                        stroke-width="1.2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </CopyToClipboard>
                                {copy === index && (
                                  <p className="copy-text">Copied</p>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item?.email}</td>
                      <td>{item?.deniedPurchaseCount}</td>
                      <td>
                        <a
                          className="custom-a-style"
                          onClick={() => {
                            getCSVData(item?._id);
                          }}
                        >
                          Download CSV
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : loader ? (
                <p
                  className=""
                  style={{
                    color: "#000",
                    textAlign: "center",
                    marginTop: 100,
                  }}
                >
                  Loading...
                </p>
              ) : tableData?.length == 0 ? (
                <p
                  className=""
                  style={{
                    color: "#000",
                    textAlign: "center",
                    marginTop: 100,
                  }}
                >
                  No data found!
                </p>
              ) : null}
            </tbody>
          </table>

          <div className="footer-content mt-2">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z" fill="#329879"/>
                          <path d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z" fill="#329879"/>
                          <path d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z" fill="#329879"/>
                    
                      </svg>
                        }
                        nextLabel={
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z" fill="#329879"/>
                          <path d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z" fill="#329879"/>
                          <path d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z" fill="#329879"/>
                        </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page}
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="accmblview d-none">
          <div className="main-twice-text">
            <h6 className="accmblviewhead">Email Address </h6>
          </div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="accheadermain">
                  <p className="acctext">luis123@gmail.com</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="acctexts">
                  <h6 className="textleft">Leader</h6>
                  <p className="textright">
                    Luis <br /> <span>Ambassador</span>
                  </p>
                </div>
                <div className="acctexts">
                  <h6 className="textleft">Access Code</h6>
                  <p className="textright">
                    141235123{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        d="M9.74726 8.49972L9.77365 10.9496C9.79563 12.9911 8.9878 13.8165 6.94626 13.8385L4.4964 13.8649C2.45485 13.8869 1.62944 13.0791 1.60745 11.0375L1.58107 8.58766C1.55909 6.54611 2.36691 5.7207 4.40846 5.69871L6.85832 5.67233C8.89987 5.65035 9.72528 6.45817 9.74726 8.49972Z"
                        stroke="#B1B1B1"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.2097 4.96237L13.236 7.41223C13.258 9.45378 12.4502 10.2792 10.4087 10.3012L9.76703 10.3081L9.74756 8.49985C9.72557 6.45831 8.90016 5.65048 6.85861 5.67246L5.05038 5.69194L5.04347 5.05031C5.02149 3.00876 5.82931 2.18335 7.87086 2.16136L10.3207 2.13498C12.3623 2.113 13.1877 2.92082 13.2097 4.96237Z"
                        stroke="#B1B1B1"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </div>
                <div className="acctexts">
                  <h6 className="textleft">Wallet Address</h6>
                  <p className="textright">
                    0x524...t56{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        d="M10.0803 8.49972L10.1067 10.9496C10.1286 12.9911 9.32081 13.8165 7.27926 13.8385L4.82941 13.8649C2.78786 13.8869 1.96244 13.0791 1.94046 11.0375L1.91408 8.58766C1.89209 6.54611 2.69992 5.7207 4.74147 5.69871L7.19133 5.67233C9.23288 5.65035 10.0583 6.45817 10.0803 8.49972Z"
                        stroke="#B1B1B1"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.5427 4.96237L13.5691 7.41223C13.591 9.45378 12.7832 10.2792 10.7417 10.3012L10.1 10.3081L10.0806 8.49985C10.0586 6.45831 9.23317 5.65048 7.19162 5.67246L5.38339 5.69194L5.37648 5.05031C5.3545 3.00876 6.16232 2.18335 8.20387 2.16136L10.6537 2.13498C12.6953 2.113 13.5207 2.92082 13.5427 4.96237Z"
                        stroke="#B1B1B1"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </div>
                <div className="acctexts">
                  <h6 className="textleft">Error Count</h6>
                  <p className="textright">569</p>
                </div>
                <div className="acctexts">
                  <h6 className="textleft">User Emails</h6>
                  <p className="textright">
                    <a href="#" className="custom-a-style">
                      Download CSV
                    </a>
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="footer-content mt-2">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path d="M7.88904 1.93555L5.38763 1.93555L1.99459 7.99682L4.496 7.99682L7.88904 1.93555Z" fill="#329879"/>
                            <path d="M7.88904 14.0703L5.38763 14.0703L1.99459 8.00904L3.57108 6.96117L4.496 8.00904L7.88904 14.0703Z" fill="#329879"/>
                            <path d="M8.01655 6.85742H14.9616L13.6869 9.14313H6.74182L8.01655 6.85742Z" fill="#329879"/>
                      
                        </svg>
                        }
                        nextLabel={
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path d="M9.11108 1.93555L11.6125 1.93555L15.0055 7.99682L12.5041 7.99682L9.11108 1.93555Z" fill="#329879"/>
                          <path d="M9.11108 14.0703L11.6125 14.0703L15.0055 8.00904L13.429 6.96117L12.5041 8.00904L9.11108 14.0703Z" fill="#329879"/>
                          <path d="M3.31318 6.85742H10.2582L8.98351 9.14313H2.03845L3.31318 6.85742Z" fill="#329879"/>
                        </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page}
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataTable;
