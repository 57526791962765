import React, { useState } from "react";
import "./datatable.scss";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion, Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";

const DataTable = ({
  tokenData,
  toggleStatus,
  setOrderDirection,
  setOrderField,
}) => {
  const [copy, setCopy] = useState(false);
  const textCopiedFun2 = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div
                    className="parent-tag"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOrderField("name");
                      setOrderDirection((prev) =>
                        prev == -1 ? 1 : prev == 1 ? -1 : -1
                      );
                    }}
                  >
                    Token
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="parent-tag"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOrderField("symbol");
                      setOrderDirection((prev) =>
                        prev == -1 ? 1 : prev == 1 ? -1 : -1
                      );
                    }}
                  >
                    Symbol
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="parent-tag"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOrderField("decimal");
                      setOrderDirection((prev) =>
                        prev == -1 ? 1 : prev == 1 ? -1 : -1
                      );
                    }}
                  >
                    Token Decimal
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="parent-tag"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOrderField("address");
                      setOrderDirection((prev) =>
                        prev == -1 ? 1 : prev == 1 ? -1 : -1
                      );
                    }}
                  >
                    Contract Address
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>

                <th>
                  <div
                    className="parent-tag"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOrderField("status");
                      setOrderDirection((prev) =>
                        prev == -1 ? 1 : prev == 1 ? -1 : -1
                      );
                    }}
                  >
                    Status
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#77868B"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#77868B"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tokenData?.length > 0
                ? tokenData?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="forflex">
                              <div className="tokenimg">
                                {item?.address ==
                                "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE" ? (
                                  <img
                                    src="\assets\ethereum-et.svg"
                                    alt="innertokenimg"
                                    className="innertokenimg"
                                  />
                                ) : (
                                  <img
                                    src={item?.imageUrl}
                                    alt="innertokenimg"
                                    className="innertokenimg"
                                  />
                                )}
                              </div>
                              {item?.address ==
                              "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE" ? (
                                <p className="tokenname">Ethereum</p>
                              ) : (
                                <p className="tokenname">{item?.name}</p>
                              )}
                            </div>
                          </td>
                          <td>{item?.symbol}</td>
                          <td>{item?.decimal}</td>
                          <td>
                            <div className="forflex">
                              {/* <p className="tokenname">{item?.address}</p> */}
                              {copy ? (
                                <span className="adadasdasdasdasdasdsad">
                                  {item?.address}
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    className=""
                                  >
                                    <path
                                      d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <>
                                  {item?.address && (
                                    <CopyToClipboard
                                      text={item?.address}
                                      onCopy={textCopiedFun2}
                                    >
                                      <span className="adadasdasdasdasdasdsad">
                                        {item?.address}
                                        <svg
                                          style={{ cursor: "pointer" }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          className=""
                                        >
                                          <path
                                            d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                            stroke="#77868B"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                            stroke="#77868B"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </CopyToClipboard>
                                  )}
                                </>
                              )}
                            </div>
                          </td>

                          {item?.status == false ? (
                            <td className="red">Inactive</td>
                          ) : (
                            <td className="green">Active</td>
                          )}

                          <td>
                            <div className="parent-toggle-switch-custom">
                              <div class="custom-toggle">
                                <input
                                  type="checkbox"
                                  class="checkbox"
                                  id={item?._id}
                                  defaultChecked={item?.status}
                                  onChange={() => {
                                    toggleStatus(item?._id);
                                  }}
                                />
                                <label class="switch red" for={item?._id}>
                                  <span class="slider"></span>
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                : null}
            </tbody>
          </table>
          {/* <div className="footer-content">
            <div className="left-f">
            </div>
            <nav className="right-f">
              <ul className="pagination">
                <li className="page-item">
                  <button
                    onClick={() => getPrevData(currentPage)}
                    className="page-link arrowssss scsdsdfefssdvsdvsd"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M10 13L5 8L10 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </button>
                </li>
                {pages?.map((item, index) => {
                  return (
                    <li key={index} className="page-item cursor-pointer">
                      <p
                        className={
                          "page-link " +
                          (index + 1 === parseInt(currentPage)
                            ? "active-pag"
                            : "")
                        }
                        onClick={() => GetAllRounds(index + 1)}
                        style={{ fontSize: "13px !important" }}
                      >
                        {index + 1}
                      </p>
                    </li>
                  );
                })}
                <li className="page-item">
                  <button
                    onClick={() => getNextData(currentPage)}
                    className="page-link arrowssss"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M5 13L10 8L5 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </button>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
        {tokenData?.length > 0
          ? tokenData?.map((item, index) => {
              return (
                <>
                  <div className="accmblviewfdsasdf d-none">
                    <div className="main-twice-text">
                      <h6 className="accmblviewhead">Token</h6>
                      <h6 className="accmblviewhead">Status</h6>
                    </div>
                    <Accordion>
                      <Accordion.Item>
                        <Accordion.Header>
                          <div className="accheadermain">
                            <div className="forflex">
                              <div className="tokenimg">
                                {item?.address ==
                                "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE" ? (
                                  <img
                                    src="\assets\ethereum-et.svg"
                                    alt="innertokenimg"
                                    className="innertokenimg"
                                  />
                                ) : (
                                  <img
                                    src={item?.imageUrl}
                                    alt="innertokenimg"
                                    className="innertokenimg"
                                  />
                                )}
                              </div>
                              {item?.address ==
                              "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE" ? (
                                <p className="tokenname">Ethereum</p>
                              ) : (
                                <p className="tokenname">{item?.name}</p>
                              )}
                            </div>
                            {item?.status == false ? (
                              <p className="acctext red">Inactive</p>
                            ) : (
                              <p className="acctext green">Active</p>
                            )}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="acctexts">
                            <h6 className="textleft">Symbol</h6>
                            <p className="textright">{item?.symbol}</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Decimals</h6>
                            <p className="textright">{item?.decimal}</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Contract Address</h6>
                            <div className="forflex">
                              {copy ? (
                                <span
                                  className="adadasdasdasdasdasdsad"
                                  style={{ display: "flex" }}
                                >
                                  <p className="textright">
                                    {item?.address?.slice(0, 7)}...
                                    {item?.address?.slice(
                                      item?.address?.length - 4,
                                      item?.address?.length
                                    )}{" "}
                                  </p>
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    className=""
                                  >
                                    <path
                                      d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <>
                                  {item?.address && (
                                    <CopyToClipboard
                                      text={item?.address}
                                      onCopy={textCopiedFun2}
                                    >
                                      <span
                                        className="adadasdasdasdasdasdsad"
                                        style={{ display: "flex" }}
                                      >
                                        <p className="textright">
                                          {item?.address?.slice(0, 7)}...
                                          {item?.address?.slice(
                                            item?.address?.length - 4,
                                            item?.address?.length
                                          )}{" "}
                                        </p>
                                        <svg
                                          style={{ cursor: "pointer" }}
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          className=""
                                        >
                                          <path
                                            d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                            stroke="#77868B"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                            stroke="#77868B"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </CopyToClipboard>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    {/* <div className="footer-content">
            <div className="left-f">
            </div>
            <nav className="right-f">
              <ul className="pagination">
                <li className="page-item">
                  <button
                    onClick={() => getPrevData(currentPage)}
                    className="page-link arrowssss scsdsdfefssdvsdvsd"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M10 13L5 8L10 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                  </button>
                </li>
                {pages?.map((item, index) => {
                  return (
                    <li key={index} className="page-item cursor-pointer">
                      <p
                        className={
                          "page-link " +
                          (index + 1 === parseInt(currentPage)
                            ? "active-pag"
                            : "")
                        }
                        onClick={() => GetAllRounds(index + 1)}
                        style={{ fontSize: "13px !important" }}
                      >
                        {index + 1}
                      </p>
                    </li>
                  );
                })}
                <li className="page-item">
                  <button
                    onClick={() => getNextData(currentPage)}
                    className="page-link arrowssss"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M5 13L10 8L5 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                  </button>
                </li>
              </ul>
            </nav>
          </div> */}
                  </div>
                </>
              );
            })
          : null}
      </section>
    </>
  );
};

export default DataTable;
