import web3NoAccount from "./web3";
import tomiContract from "./tomiAbi2.json";
import createRoundDop from "./PreSale.json";
import ERC20Token from "./erc20TokenABI.json";
import Claims from "./Claims.json";
import ClaimNewLatest from "./claimNewLatest.json";

import ClaimsNew from "./ClaimsNew.json";
import insitutionPreSale from "./institutionPreSaleAbi.json";

import LEIAPresale from "./LEIAPreSale.json";
import LEIAClaims from "./LEIAClaims.json";
import LEIAInstitutionPresale from "./LEIAInstitutionPresaleAbi.json";

import NFAPresale from "./NFAPreSale.json";
import NFAClaims from "./NFAClaims.json";

import AstroNovaPresale from "./AstroNovaPreSale.json";
import AstroNovaClaims from "./AstroNovaClaims.json";

import MinersClaims from "./MinersClaims.json";

import GEMSPresale from "./GEMSPreSale.json";
import GEMSClaims from "./GEMSClaims.json";

import OlympusPresale from "./OlympusPreSale.json";
import OlympusClaims from "./OlympusClaims.json";
import OlympusInstitutionPresale from "./OlympusInstitutionPresaleAbi.json";

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract(abi, address);
};

export const getTomiContract = (address, web3) => {
  return getContract(tomiContract, address, web3);
};

export const getPreSaleContract = (address, web3) => {
  return getContract(createRoundDop, address, web3);
};

export const getClaimsContract = (address, web3) => {
  return getContract(Claims, address, web3);
};

export const getLEIAPreSaleContract = (address, web3) => {
  return getContract(LEIAPresale, address, web3);
};

export const getLEIAClaimsContract = (address, web3) => {
  return getContract(LEIAClaims, address, web3);
};

export const getNFAPreSaleContract = (address, web3) => {
  return getContract(NFAPresale, address, web3);
};

export const getNFAClaimsContract = (address, web3) => {
  return getContract(NFAClaims, address, web3);
};

export const getAstroNovaPreSaleContract = (address, web3) => {
  return getContract(AstroNovaPresale, address, web3);
};

export const getAstroNovaClaimsContract = (address, web3) => {
  return getContract(AstroNovaClaims, address, web3);
};

export const getMinersClaimsContract = (address, web3) => {
  return getContract(MinersClaims, address, web3);
};

export const getGEMSPreSaleContract = (address, web3) => {
  return getContract(GEMSPresale, address, web3);
};

export const getGEMSClaimsContract = (address, web3) => {
  return getContract(GEMSClaims, address, web3);
};

export const getOlympusPreSaleContract = (address, web3) => {
  return getContract(OlympusPresale, address, web3);
};

export const getOlympusClaimsContract = (address, web3) => {
  return getContract(OlympusClaims, address, web3);
};

export const getERC20Token = (address, web3) => {
  return getContract(ERC20Token, address, web3);
};
///////////////////////////////////////////////////////////
export const getClaimNewLatest = (address, web3) => {
  return getContract(ClaimNewLatest, address, web3);
};

export const getclaimscontract = (address, web3) => {
  return getContract(Claims, address, web3);
};

export const getclaimsnewcontract = (address, web3) => {
  return getContract(ClaimsNew, address, web3);
};
export const getinstitutionpresalecontract = (address, web3) => {
  return getContract(insitutionPreSale, address, web3);
};

export const getOlympusInstitutionPreSaleContract = (address, web3) => {
  return getContract(OlympusInstitutionPresale, address, web3);
};

export const getLEIAInstitutionPreSaleContract = (address, web3) => {
  return getContract(LEIAInstitutionPresale, address, web3);
};
